import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '../api/api';

const getBuilding = createAsyncThunk(
  'building/get',
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.get(`/api/disp/objects/${data.streetId}/${data.streetNumber}/${data.subnumber}/${data.subnumber_2}`);
      return response.data;
    } catch (error) {
      let errorValue;
      if (Object.hasOwn(error, 'response')) {
        errorValue = error.response.data;
      }
      else {
        errorValue = error.message;
      }
      return rejectWithValue(errorValue);
    }
  }
);

const getBuildingByIdent = createAsyncThunk(
  'buildingByIdent/get',
  async (identId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/api/disp/objects/${identId}`);
      return response.data;
    } catch (error) {
      let errorValue;
      if (Object.hasOwn(error, 'response')) {
        errorValue = error.response.data;
      }
      else {
        errorValue = error.message;
      }
      return rejectWithValue(errorValue);
    }
  }

)

const getSpacesByAddress = createAsyncThunk(
  'spacesByAddress/get',
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.get(`/api/disp/spaces/${data.streetId}/${data.streetNumber}/${data.subnumber}/${data.subnumber_2}`);
      return response.data;
    } catch (error) {
      let errorValue;
      if (Object.hasOwn(error, 'response')) {
        errorValue = error.response.data;
      }
      else {
        errorValue = error.message;
      }
      return rejectWithValue(errorValue);
    }
  }
)

const getSpacesByIdent = createAsyncThunk(
  'spacesByIdent/get',
  async (objectId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/api/disp/spaces/${objectId}`);
      return response.data;
    } catch (error) {
      let errorValue;
      if (Object.hasOwn(error, 'response')) {
        errorValue = error.response.data;
      }
      else {
        errorValue = error.message;
      }
      return rejectWithValue(errorValue);
    }
  }
)

const checkDamagesByIdent = createAsyncThunk(
  'damagesByIdent/get',
  async ({ objectId, startDate, endDate }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/api/v1.0/salesforce/sobjects/${objectId}/cases`, {}, {
        params: {
          start_date: startDate,
          end_date: endDate
        }
      });
      return response.data;
    } catch (error) {
      let errorValue;
      if (Object.hasOwn(error, 'response')) {
        errorValue = error.response.data;
      }
      else {
        errorValue = error.message;
      }
      return rejectWithValue(errorValue);
    }
  })


const checkDamagesByAddress = createAsyncThunk(
  'damagesByAddress/get',
  async ({ objectId, startDate, endDate }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/api/v1.0/salesforce/sobjects/${objectId}/cases`, {}, {
        params: {
          start_date: startDate,
          end_date: endDate
        }
      });
      return response.data;
    } catch (error) {
      let errorValue;
      if (Object.hasOwn(error, 'response')) {
        errorValue = error.response.data;
      }
      else {
        errorValue = error.message;
      }
      return rejectWithValue(errorValue);
    }
  })

const sendDataToSf = createAsyncThunk(
  'callCenterSF/post',
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post(`/api/v1.0/salesforce/sobjects/case/`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      let errorValue;
      if (Object.hasOwn(error, 'response')) {
        errorValue = error.response.data;
      }
      else {
        errorValue = error.message;
      }
      return rejectWithValue(errorValue);
    }
  }
)

export { getBuilding, getBuildingByIdent, getSpacesByAddress, getSpacesByIdent, checkDamagesByIdent, checkDamagesByAddress, sendDataToSf };