import { createSlice } from '@reduxjs/toolkit';

import { getBuilding } from '../../../thunks/callCenterThunks';

const initialState = {
  buildingData: null,
  isBuildingLoading: false,
  buildingError: null
}

const buildingSlice = createSlice({
  name: 'building',
  initialState,
  reducers: {
    resetBuildingData: (state, action) => {
      state.buildingData = null;
      state.isBuildingLoading = false;
      state.buildingError = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getBuilding.pending, (state, action) => {
      state.isBuildingLoading = true;
      state.buildingError = null;
    });
    builder.addCase(getBuilding.fulfilled, (state, action) => {
      state.isBuildingLoading = false;
      state.buildingData = action.payload;
      state.buildingError = null;
    });
    builder.addCase(getBuilding.rejected, (state, action) => {
      state.isBuildingLoading = false;
      state.buildingError = action.payload;
    });
  }
});

export const { resetBuildingData } = buildingSlice.actions;
export const buildingReducer = buildingSlice.reducer;