import { createSlice } from '@reduxjs/toolkit';
import { checkDamagesByAddress } from '../../../thunks/callCenterThunks';

const initialState = {
  damages: null,
  isDamagesLoading: false,
  damagesError: null
}

const damagesByAddressSlice = createSlice({
  name: 'damagesByAddress',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(checkDamagesByAddress.pending, (state, action) => {
      state.isDamagesLoading = true;
      state.damagesError = null;
    });
    builder.addCase(checkDamagesByAddress.fulfilled, (state, action) => {
      state.isDamagesLoading = false;
      state.damages = action.payload;
      state.damagesError = null;
    });
    builder.addCase(checkDamagesByAddress.rejected, (state, action) => {
      state.isDamagesLoading = false;
      state.damagesError = action.payload;
    });
  }
});


export const damagesByAddressReducer = damagesByAddressSlice.reducer;