import { useDispatch, useSelector } from 'react-redux';
import { Button, Alert } from 'antd';

import ReferClass from './ReferClass';
import { selectReferItem } from '../features/refers/referClassSlice';

import { referClassItems } from '../data/data';
import { BUTTON_NEXT, ERROR_REQUIRED_REFER_CLASS } from '../data/constants';
import { setErrorMessage } from '../features/errors/errorSlice';

const TabReferClass = (props) => {
  const { activeKey } = useSelector((state) => state.tabs);
  const { selectedItemId } = useSelector((state) => state.referClass);
  const { message } = useSelector(state => state.error)

  const dispatch = useDispatch();

  // const [errorMessage, setErrorMessage] = useState('')

  const selectReferType = (id) => {
    dispatch(selectReferItem(id));
  }

  const changeTabNext = () => {
    if (selectedItemId === 0) {
      dispatch(setErrorMessage(`${ERROR_REQUIRED_REFER_CLASS}`));
      setTimeout(() => {
        dispatch(setErrorMessage(''));
      }, 4000);
      return;
    }
    const newActiveKey = activeKey + 1;
    props.nextTab(newActiveKey);
  }

  return (
    <div>
      <div className='tabContentContainer'>
        {referClassItems.map(item => {
          return <ReferClass key={item.id} id={item.id} data={item} selectType={selectReferType} />
        })}

      </div>
      <div className='errorContainer'>
        <Alert style={{ display: `${message !== '' ? 'block' : 'none'}` }} message={message} type="error" />
      </div>
      <div className='tab_1_buttonsContainer'>
        <Button onClick={changeTabNext} className='btnNext'>{BUTTON_NEXT}</Button>
      </div>
    </div>
  )
}

export default TabReferClass;