import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedItemId: 0
}

const referClassSlice = createSlice({
  name: 'referClass',
  initialState,
  reducers: {
    selectReferItem: (state, action) => {
      state.selectedItemId = action.payload;
    },
    resetItemId: (state, action) => {
      state.selectedItemId = 0;
    }
  }
});

export const { selectReferItem, resetItemId } = referClassSlice.actions;
export const referClassReducer = referClassSlice.reducer;