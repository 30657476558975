import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: ''
}

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setErrorMessage: (state, action) => {
      state.message = action.payload;
    }
  }
});

export const { setErrorMessage } = errorSlice.actions;
export const errorReducer = errorSlice.reducer;