import { createSlice } from '@reduxjs/toolkit';
import { getSpacesByIdent } from '../../../../thunks/callCenterThunks';


const initialState = {
  spaceTypesIdent: [],
  isSpacesIdentLoading: false,
  isSpacesIdentError: null,
}

const spaceTypesByIdentSlice = createSlice({
  name: 'spaceTypesByIdent',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getSpacesByIdent.pending, (state, action) => {
      state.isSpacesIdentLoading = true;
      state.isSpacesIdentError = null;
    });
    builder.addCase(getSpacesByIdent.fulfilled, (state, action) => {
      state.isSpacesIdentLoading = false;
      state.spaceTypesIdent = action.payload;
      state.isSpacesIdentError = null;
    });
    builder.addCase(getSpacesByIdent.rejected, (state, action) => {
      state.isSpacesIdentLoading = false;
      state.isSpacesIdentError = action.payload;
    });
  }
});

export const spaceTypesByIdentReducer = spaceTypesByIdentSlice.reducer;