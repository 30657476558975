import { STREET, STREET_NUMBER, STREET_SUBNUMBER, STREET_SUBNUMBER_2, SPACE_TYPE, SPACE_LABEL } from '../../data/constants';
import { Button, Input, Select, Modal, Table, Spin } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { changeIdent, changeObjectData, changeSpaceType, changeSpaceLabel, resetSpaceTypeAndLabel } from '../../features/infrastructure/call-center/ident/identFormDataSlice';
import { checkDamagesByIdent, getBuildingByIdent, getSpacesByIdent } from '../../thunks/callCenterThunks';
import { useEffect, useState } from 'react';
import lodash from 'lodash';
import { changeLabelOptions, changeLabelsByType, changeTypeOptions } from '../../features/infrastructure/call-center/ident/spaceOptionSlice';
import { setErrorMessage } from '../../features/errors/callCenterErrorSlice';

const SpaceDataIdent = () => {
  const { number, additionToNumber, secondAddition, object, ident, streetName, spaceType, spaceLabel, streetId } = useSelector(state => state.identFormData);
  const { streets } = useSelector(state => state.callCenterStreets);
  const { buildingIdentData } = useSelector(state => state.buildingIdent);
  const { spaceTypesIdent } = useSelector(state => state.spaceTypesByIdent);
  const { typeOptions, labelOptions, labelsByType } = useSelector(state => state.spaceOptions);
  const { damages, isDamagesLoading, damagesError } = useSelector(state => state.damagesByIdent);


  const [visible, setVisible] = useState('none');
  const [modalOpen, setModalOpen] = useState(false);

  const [poremecajiDataSource, setPoremecajiDataSource] = useState([]);
  const [predmetiDataSource, setPredmetiDataSource] = useState([]);

  const [brojPoremecaja, setBrojPoremecaja] = useState(0);
  const [brojKvarova, setBrojKvarova] = useState(0);

  // const [disable, setDisable] = useState(true);

  const columns = [
    {
      title: 'Број предмета',
      dataIndex: 'brojPredmeta'
    },
    {
      title: 'Подносилац',
      dataIndex: 'podnosilac'
    },
    {
      title: 'Датум и време пријаве',
      dataIndex: 'datumPrijave'
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    if (buildingIdentData && ident !== object.toString()) {
      const street = streets.filter(item => item.id_ulica === buildingIdentData.id_ulica)[0];
      const objectData = {
        streetId: street.id_ulica,
        streetName: `${street.naziv} (${street.naselje_naziv})`,
        streetNumber: buildingIdentData.kucni_broj,
        subnumber: buildingIdentData.kucni_broj_dopuna1,
        subnumber_2: buildingIdentData.kucni_broj_dodatak2,
        object: buildingIdentData.id_objekat

      }
      dispatch(changeObjectData(objectData));
      dispatch(getSpacesByIdent(buildingIdentData.id_objekat));
      //setVisible('block')
    }
  }, [buildingIdentData]);

  useEffect(() => {
    if (spaceTypesIdent.length > 0) {
      createOptions();
    }
  }, [spaceTypesIdent]);

  useEffect(() => {
    if (damages) {
      createDataForModal();
      setVisible('block');
    }

  }, [damages]);

  const createDataForModal = () => {
    const grouped = lodash.groupBy(damages, 'Record_Type_Formula__c');
    const regularniPredmeti = grouped['Regularni predmet'];
    const poremecaji = grouped['Poremećaj'];

    let dataSourcePredmeti = [];
    let dataSourcePoremecaji = [];

    if (regularniPredmeti) {
      dataSourcePredmeti = regularniPredmeti.map((item, index) => {
        return {
          key: index,
          brojPredmeta: item.CaseNumber,
          podnosilac: `${item.Ime__c} ${item.Prezime__c}`,
          datumPrijave: formatDatumPrijave(item.CreatedDate)
        }
      });
    }
    if (poremecaji) {
      dataSourcePoremecaji = poremecaji.map((item, index) => {
        return {
          key: index,
          brojPredmeta: item.CaseNumber,
          podnosilac: `${item.Ime__c} ${item.Prezime__c}`,
          datumPrijave: formatDatumPrijave(item.CreatedDate)
        }
      });
    }

    setPredmetiDataSource(dataSourcePredmeti);
    setBrojKvarova(dataSourcePredmeti.length);

    setPoremecajiDataSource(dataSourcePoremecaji);
    setBrojPoremecaja(dataSourcePoremecaji.length);
  }

  const formatDatumPrijave = (stringDate) => {
    // stringDate yyyy-mm-ddThh:mm:ss.00+0000
    const splitted = stringDate.split('T');
    const date = splitted[0];
    const time = splitted[1].slice(0, 8);
    const splittedTime = time.split(':');
    // add 2 hours to current time value
    const timeString = splittedTime[0];
    const timeNum = Number(timeString);
    const newTimeNum = timeNum + 2;
    const zero = newTimeNum.toString().length > 1 ? '' : '0';
    const newStringTime = `${zero}${newTimeNum}:${splittedTime[1]}:${splittedTime[2]}`

    return `${date} ${newStringTime}`

  }
  const createOptions = () => {
    const spaceOptions = [];
    const labelsByType = {};

    const grouped = lodash.groupBy(spaceTypesIdent, 'vrsta_prostora_id');
    for (const [key, value] of Object.entries(grouped)) {
      spaceOptions.push({ value: key, label: value[0].vrsta_prostora });

      let labelArray = [];
      value.map((item) => {
        labelArray.push({ value: item.id_prostor, label: `${item.id_prostor} (${item.oznaka} - ${item.dopuna_oznake})` })
      });

      labelsByType[key] = labelArray;
    }
    dispatch(changeTypeOptions(spaceOptions));
    dispatch(changeLabelsByType(labelsByType));


  }

  const onIdentChange = (e) => {
    dispatch(changeIdent(e.target.value));
  }

  const getBuilding = async () => {
    if (ident !== object.toString()) {
      setVisible('none');
      dispatch(getBuildingByIdent(ident));
      dispatch(resetSpaceTypeAndLabel());
      dispatch(changeLabelOptions([]));
    }

  }

  const onSpaceTypeChange = (value, option) => {
    const labelOptions = labelsByType[value];
    dispatch(changeSpaceType(option.label));
    dispatch(changeLabelOptions(labelOptions));
  }

  const onSpaceLabelChange = (value) => {
    dispatch(changeSpaceLabel(value));
  }

  const checkRefers = () => {
    if (!object) {
      setError('Ознака објекта је обавезно поље за проверу обраћања!');
      return;
    }
    setVisible('block');

    let endDate = new Date();
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 4);

    let isoStart = startDate.toISOString().slice(0, 10);
    let isoEnd = endDate.toISOString().slice(0, 10);

    dispatch(checkDamagesByIdent({ objectId: object, startDate: isoStart, endDate: isoEnd }))
  }

  const setError = (error) => {
    dispatch(setErrorMessage(error));
    setTimeout(() => {
      dispatch(setErrorMessage(''));
    }, 4000);
  }

  return (
    <>
      <div className='left'>
        <div className='ident_group formGroup'>
          <Input placeholder='Унеси ИДЕНТ' type='number' value={ident} onChange={onIdentChange} />
          <Button onClick={getBuilding} className='btnCheckIdent'>Провери</Button>
        </div>
        <div className='formGroup'>
          <label>{STREET}<span>*</span></label>
          <Input value={streetName} className='formField' disabled type='text' />
        </div>
        <div className='formGroup'>
          <label>{STREET_NUMBER}<span>*</span></label>
          <Input value={number} className='formField' disabled type='text' />
        </div>
        <div className='formGroup'>
          <label>{STREET_SUBNUMBER}</label>
          <Input value={additionToNumber} className='formField' disabled type='text' />
        </div>
        <div className='formGroup'>
          <label>{STREET_SUBNUMBER_2}</label>
          <Input value={secondAddition} className='formField' disabled type='text' />
        </div>
      </div>
      <div className='right' >
        <div style={{ marginTop: '1.5rem' }} className='formGroup'>
          <label>Ознака објекта</label>
          <Input value={object} className='formField' type='text' disabled={true} />
        </div>
        <div className='formGroup'>
          <label>{SPACE_TYPE}<span>*</span></label>
          <Select value={spaceType} className='formField' options={typeOptions} onChange={onSpaceTypeChange} />
        </div>
        <div className='formGroup'>
          <label>{SPACE_LABEL}<span>*</span></label>
          <Select value={spaceLabel} className='formField' options={labelOptions} onChange={onSpaceLabelChange} />
        </div>
        <div style={{ display: `${visible}` }} className='formGroup'>
          {isDamagesLoading && <Spin style={{ display: 'block' }} tip='Учитавање у току...' />}
          {damagesError && setError()}
          {
            !isDamagesLoading && !damagesError &&
            <div>
              <p>Број пријављених поремећаја је: {`${brojPoremecaja}`}</p>
              <p>Број пријављених кварова је: {`${brojKvarova}`}</p>
              <div style={{ color: '#3699FF', cursor: 'pointer' }} onClick={() => setModalOpen(true)}>{'[види све]'}</div>
            </div>
          }

        </div>
        <div className='formGroup'>
          <Button style={{ float: 'right' }} className='btnFindBuilding' onClick={checkRefers}>Провери обраћања</Button>

          <Modal
            className='modalContainer'
            title='Поремећаји и кварови'
            centered
            open={modalOpen}
            onCancel={() => setModalOpen(false)}
            footer={[
              <Button className='btnCloseModal' onClick={() => setModalOpen(false)}>Затвори</Button>
            ]}
          >
            <div className='modalBody'>
              <div className='modalRow'>
                <div className='poremecajiContainer'>
                  {brojPoremecaja === 0
                    ?
                    <h4>Није било поремећаја у последњих 5 дана</h4>
                    :
                    <div>
                      <h4>Поремећаји u poslednjih 5 dana</h4>
                      <Table dataSource={poremecajiDataSource} columns={columns} />
                    </div>
                  }
                </div>
                <div className='poremecajiContainer'>
                  {brojKvarova === 0
                    ?
                    <h4>Није било кварова у последњих 5 дана</h4>
                    :
                    <div>
                      <h4>Кварови u poslednjih 5 dana</h4>
                      <Table dataSource={predmetiDataSource} columns={columns} />
                    </div>
                  }
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  )
}

export default SpaceDataIdent;