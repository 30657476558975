import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  lastname: '',
  user: '',
  email: '',
  phone: ''
}

const referSubmitterSlice = createSlice({
  name: 'referSubmitter',
  initialState,
  reducers: {
    changeData: (state, action) => {
      state[action.payload.property] = action.payload.value;
    },
    resetData: (state, action) => {
      state.name = '';
      state.lastname = '';
      state.user = '';
      state.email = '';
      state.phone = '';
    }
  }
});

export const { changeData, resetData } = referSubmitterSlice.actions;
export const referSubmitterReducer = referSubmitterSlice.reducer;