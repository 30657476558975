import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { errorReducer } from '../features/errors/errorSlice';
import { additionSliceReducer } from '../features/infrastructure/additionSlice';
import { infraDataSliceReducer } from '../features/infrastructure/infraDataSlice';
import { salesForceSliceReducer } from '../features/infrastructure/salesForceSlice';
import { secondAdditionSliceReducer } from '../features/infrastructure/secondAdditionSlice';
import { streetNumberSliceReducer } from '../features/infrastructure/streetNumberSlice';
import { streetSliceReducer } from '../features/infrastructure/streetSlice';
import { referClassReducer } from '../features/refers/referClassSlice';
import { referSubmitterReducer } from '../features/refers/referSubmitterSlice';
import { referTypeReducer } from '../features/refers/referTypeSlice';
import { tabsReducer } from '../features/tabs/tabsSlice';
import { callCenterTabsReducer } from '../features/tabs/callCenterTabsSlice';
import { callCenterInfraDataReducer } from '../features/infrastructure/call-center/callCenterInfraDataSlice';
import { streetNumReducer } from '../features/infrastructure/call-center/streetNumSlice';
import { callCenterAdditionsReducer } from '../features/infrastructure/call-center/subnumberSlice';
import { callCenterSecondAdditionReducer } from '../features/infrastructure/call-center/subnumber2Slice';
import { buildingReducer } from '../features/infrastructure/call-center/buildingSlice';
import { streetsBgReducer } from '../features/infrastructure/call-center/streetsBgSlice';
import { identFormDataReducer } from '../features/infrastructure/call-center/ident/identFormDataSlice';
import { submitterReducer } from '../features/infrastructure/call-center/submitterSlice';
import { callCenterErrorReducer } from '../features/errors/callCenterErrorSlice';
import { referDescReducer } from '../features/infrastructure/call-center/referDescSlice';
import { spaceTypesByAddressReducer } from '../features/infrastructure/call-center/spaceTypesByAddressSlice';
import { buildingIdentReducer } from '../features/infrastructure/call-center/ident/buildingByIdentSlice';
import { spaceTypesByIdentReducer } from '../features/infrastructure/call-center/ident/spaceTypesByIdent';
import { spaceOptionsReducer } from '../features/infrastructure/call-center/ident/spaceOptionSlice';
import { spaceOptionsByAddressReducer } from '../features/infrastructure/call-center/spaceOptionsByAddressSlice';
import { damagesByIdentReducer } from '../features/infrastructure/call-center/ident/damageByIdentSlice';
import { damagesByAddressReducer } from '../features/infrastructure/call-center/damagesByAddressSlice';
import { callCenterSfReducer } from '../features/infrastructure/call-center/callCenterSfSlice';
import { activeIdentReducer } from '../features/infrastructure/call-center/ident/activeIdentSlice';



export const store = configureStore({
  reducer: {
    tabs: tabsReducer,
    referClass: referClassReducer,
    referType: referTypeReducer,
    error: errorReducer,
    referSubmitter: referSubmitterReducer,
    streets: streetSliceReducer,
    streetNumbers: streetNumberSliceReducer,
    additionsToNumber: additionSliceReducer,
    secondAddition: secondAdditionSliceReducer,
    infraData: infraDataSliceReducer,
    salesForce: salesForceSliceReducer,
    // call center
    callCenterTabs: callCenterTabsReducer,
    callCenterInfraData: callCenterInfraDataReducer,
    callCenterStreets: streetsBgReducer,
    callCenterStreetNum: streetNumReducer,
    callCenterAdditionsToNum: callCenterAdditionsReducer,
    callCenterSecondAdditions: callCenterSecondAdditionReducer,
    building: buildingReducer,
    identFormData: identFormDataReducer,
    submitter: submitterReducer,
    callCenterError: callCenterErrorReducer,
    referDesc: referDescReducer,
    spaceTypesByAddress: spaceTypesByAddressReducer,
    buildingIdent: buildingIdentReducer,
    spaceTypesByIdent: spaceTypesByIdentReducer,
    spaceOptions: spaceOptionsReducer,
    spaceOptionsByAddress: spaceOptionsByAddressReducer,
    damagesByIdent: damagesByIdentReducer,
    damagesByAddress: damagesByAddressReducer,
    callCenterSf: callCenterSfReducer,
    activeIdent: activeIdentReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
