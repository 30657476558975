import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  typeOptions: [],
  labelOptions: [],
  labelsByType: null
}

const spaceOptionByAddressSlice = createSlice({
  name: 'spaceOptionsByAddress',
  initialState,
  reducers: {
    resetSpaceOptions: (state, action) => {
      state.typeOptions = [];
      state.labelOptions = [];
      state.labelsByType = null;
    },
    changeTypeOptions: (state, action) => {
      state.typeOptions = action.payload;
    },
    changeLabelOptions: (state, action) => {
      state.labelOptions = action.payload;
    },
    changeLabelsByType: (state, action) => {
      state.labelsByType = action.payload;
    }
  }
});

export const { changeTypeOptions, changeLabelOptions, changeLabelsByType, resetSpaceOptions } = spaceOptionByAddressSlice.actions;
export const spaceOptionsByAddressReducer = spaceOptionByAddressSlice.reducer;