import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeKey: 1
}

const tabSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setActiveKey: (state, action) => {
      state.activeKey = action.payload;
    }
  }
});

export const { setActiveKey } = tabSlice.actions;
export const tabsReducer = tabSlice.reducer;