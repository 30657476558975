import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  street: '',
  number: '',
  additionToNumber: '',
  secondAddition: '',
  spaceType: '',
  spaceLabel: ''
}

const infraDataSlice = createSlice({
  name: 'infraData',
  initialState,
  reducers: {
    resetInfraData: (state, action) => {
      state.number = '';
      state.additionToNumber = '';
      state.secondAddition = '';
      state.spaceType = '';
      state.spaceLabel = '';
      state.street = '';
    },
    changeStreet: (state, action) => {
      state.street = action.payload
    },
    changeNumber: (state, action) => {
      state.number = action.payload
    },
    changeAdditionToNumber: (state, action) => {
      state.additionToNumber = action.payload
    },
    changeSecondAddition: (state, action) => {
      state.secondAddition = action.payload
    },
    changeSpaceType: (state, action) => {
      state.spaceType = action.payload
    },
    changeSpaceLabel: (state, action) => {
      state.spaceLabel = action.payload
    },

  }
});

export const {
  resetInfraData,
  changeStreet,
  changeNumber,
  changeAdditionToNumber,
  changeSecondAddition,
  changeSpaceType,
  changeSpaceLabel
} = infraDataSlice.actions;
export const infraDataSliceReducer = infraDataSlice.reducer;