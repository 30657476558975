import { createSlice } from '@reduxjs/toolkit';
import { REFER_THUNKS_TYPES } from '../../data/constants';


const initialState = {
  numbers: [],
  isNumbersLoading: false,
  errorLoadingNumbers: null,
  disabled: true
}

const streetNumberSlice = createSlice({
  name: 'streetNumber',
  initialState,
  reducers: {
    resetStreetNumbers: (state, action) => {
      state = initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(`${REFER_THUNKS_TYPES['numbers']}/pending`, (state, action) => {
      state.isNumbersLoading = true;
      state.errorLoadingNumbers = null;
      state.disabled = true;
    });
    builder.addCase(`${REFER_THUNKS_TYPES['numbers']}/fulfilled`, (state, action) => {
      state.isNumbersLoading = false;
      state.numbers = action.payload;
      state.errorLoadingNumbers = null;
      state.disabled = false;
    });
    builder.addCase(`${REFER_THUNKS_TYPES['numbers']}/rejected`, (state, action) => {
      state.isNumbersLoading = false;
      state.errorLoadingNumbers = action.payload;
      state.disabled = true;
    });
  }
});

export const { resetStreetNumbers } = streetNumberSlice.actions;
export const streetNumberSliceReducer = streetNumberSlice.reducer;