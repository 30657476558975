import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  type: '',
  subtype: '',
  description: ''
}

const referDescSlice = createSlice({
  name: 'referDesc',
  initialState,
  reducers: {
    changeReferDescData: (state, action) => {
      state[action.payload.property] = action.payload.value;
    },
    resetReferDescData: (state, action) => {
      state.type = '';
      state.subtype = '';
      state.description = '';

    }
  }
});

export const { changeReferDescData, resetReferDescData } = referDescSlice.actions;
export const referDescReducer = referDescSlice.reducer;