import { createSlice } from '@reduxjs/toolkit';
import { CALL_CENTER_THUNKS_TYPES } from '../../../data/constants';

const initialState = {
  numbers: [],
  isNumbersLoading: false,
  errorLoadingNumbers: null,
  disabled: true
}

const streetNumSlice = createSlice({
  name: 'streetNum',
  initialState,
  reducers: {
    resetStreetNumbers: (state, action) => {
      state = initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(`${CALL_CENTER_THUNKS_TYPES['numbers']}/pending`, (state, action) => {
      state.isNumbersLoading = true;
      state.errorLoadingNumbers = null;
      state.disabled = true;
    });
    builder.addCase(`${CALL_CENTER_THUNKS_TYPES['numbers']}/fulfilled`, (state, action) => {
      state.isNumbersLoading = false;
      state.numbers = action.payload;
      state.errorLoadingNumbers = null;
      state.disabled = false;
    });
    builder.addCase(`${CALL_CENTER_THUNKS_TYPES['numbers']}/rejected`, (state, action) => {
      state.isNumbersLoading = false;
      state.errorLoadingNumbers = action.payload;
      state.disabled = true;
    });
  }
});

export const { resetStreetNumbers } = streetNumSlice.actions;
export const streetNumReducer = streetNumSlice.reducer;