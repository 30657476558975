import { SUBMITTER_EMAIL, SUBMITTER_LASTNAME, SUBMITTER_NAME, SUBMITTER_PHONE, BUTTON_BACK, BUTTON_NEXT } from '../data/constants';
import { Input, Button, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { changeData, resetData } from '../features/refers/referSubmitterSlice';
import { setErrorMessage } from '../features/errors/errorSlice';
import { useEffect } from 'react';

const TabReferSubmitter = (props) => {
  const { selectedItemId } = useSelector(state => state.referClass);
  const { activeKey } = useSelector((state) => state.tabs);
  const { message } = useSelector(state => state.error);
  const { name, lastname, user, email, phone } = useSelector(state => state.referSubmitter);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetData())
  }, [selectedItemId])

  const changeTabBack = () => {
    const newActiveKey = activeKey - 1;
    props.onTabChange(newActiveKey);
  }

  const changeTabNext = () => {
    const result = validateFields();
    if (!result.isValidate) {
      setError(`${result.errorField} је обавезно поље!`);
      return;
    }

    const validEmail = validateEmail();
    if (!validEmail) {
      setError(`Емаил није у исправном формату!`)
      return;
    }

    const newActiveKey = activeKey + 1;
    props.onTabChange(newActiveKey);
  }
  const validateFields = () => {
    let isValidate = true;
    let errorField;
    if (name === '') {
      isValidate = false;
      errorField = SUBMITTER_NAME
    } else if (lastname === '') {
      isValidate = false;
      errorField = SUBMITTER_LASTNAME
    } else if (email === '') {
      isValidate = false;
      errorField = SUBMITTER_EMAIL
    } else if (phone === '') {
      isValidate = false;
      errorField = SUBMITTER_PHONE
    }

    return { isValidate, errorField }
  }

  const validateEmail = () => {
    return email.toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  }

  const onNameChange = (e) => {
    dispatch(changeData({ property: 'name', value: e.target.value }))
  }
  const onLastnameChange = (e) => {
    dispatch(changeData({ property: 'lastname', value: e.target.value }))
  }
  const onUserChange = (e) => {
    dispatch(changeData({ property: 'user', value: e.target.value }))
  }
  const onEmailChange = (e) => {
    dispatch(changeData({ property: 'email', value: e.target.value }))
  }
  const onPhoneChange = (e) => {
    dispatch(changeData({ property: 'phone', value: e.target.value }))
  }

  const setError = (error) => {
    dispatch(setErrorMessage(error));
    setTimeout(() => {
      dispatch(setErrorMessage(''));
    }, 4000);
  }

  return (
    <div>
      <div className='tabContentContainer'>
        <div style={{ width: '100%' }}>
          <div className='formGroup'>
            <label>{SUBMITTER_NAME}<span>*</span></label>
            <Input value={name} placeholder={SUBMITTER_NAME} className='formField' onChange={onNameChange} />
          </div>
          <div className='formGroup'>
            <label>{SUBMITTER_LASTNAME}<span>*</span></label>
            <Input value={lastname} placeholder={SUBMITTER_LASTNAME} className='formField' onChange={onLastnameChange} />
          </div>
          <div className='formGroup'>
            <label>Назив корисника</label>
            <Input value={user} placeholder='Назив корисника' className='formField' onChange={onUserChange} />
          </div>
          <div className='formGroup'>
            <label>{SUBMITTER_EMAIL}<span>*</span></label>
            <Input type='email' value={email} placeholder={SUBMITTER_EMAIL} className='formField' onChange={onEmailChange} />
          </div>
          <div className='formGroup'>
            <label>{SUBMITTER_PHONE}<span>*</span></label>
            <Input type='number' value={phone} placeholder={SUBMITTER_PHONE} className='formField' onChange={onPhoneChange} />
          </div>
        </div>
      </div >
      <div className='errorContainer errorTab2'>
        <Alert style={{ display: `${message !== '' ? 'block' : 'none'}` }} message={message} type="error" />
      </div>
      <div className='tab_1_buttonsContainer'>
        <Button onClick={changeTabBack} className='btnBack'>{BUTTON_BACK}</Button>
        <Button onClick={changeTabNext} className='btnNext'>{BUTTON_NEXT}</Button>
      </div>
    </div>
  )
}

export default TabReferSubmitter;