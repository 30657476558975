export const ACCEPTED_FILE_TYPE = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
export const BUTTON_NEXT = 'ДАЉЕ';
export const BUTTON_BACK = 'НАЗАД';
export const REFER_TYPE = 'Тип обраћања';
export const REFER_SUBTYPE = 'Подтип обраћања'
export const REFER_TITLE = 'Наслов обраћања';
export const REFER_DESCRIPTION = 'Опис обраћања';
export const ERROR_REQUIRED_FIELDS = 'Нису попуњени сви подаци';
export const ERROR_UNSUPPORTED_FILE = 'Недозвољен тип података за';
export const ERROR_UPLOAD_FILE_SIZE = 'Величина фајлова је прешла границу од 6МБ';
export const UPLOAD_DESCRIPTION = 'Дозвољени типови фајла: pdf, jpg, jpeg, png. Максимална величина фајлова: 6MБ (укупно).';
export const ERROR_REQUIRED_REFER_CLASS = 'Морате одабрати врсту обраћања.';
export const SUBMITTER_NAME = 'Име подносиоца';
export const SUBMITTER_LASTNAME = 'Презиме подносиоца';
export const SUBMITTER_EMAIL = 'Емаил подносиоца';
export const SUBMITTER_PHONE = 'Телефон подносиоца';
export const STREET = 'Улица';
export const STREET_NUMBER = 'Кућни број';
export const SPACE_TYPE = 'Врста простора';
export const SPACE_LABEL = 'Број стана (ознака простора)';
export const STREET_SUBNUMBER = 'Додатак кућном броју';
export const STREET_SUBNUMBER_2 = 'Допуна броја';


export const REFER_THUNKS_TYPES = {
  streets: 'streets/get',
  numbers: 'streetNumbers/get',
  additionToNumber: 'additionToNumber/get',
  secondAddition: 'secondAdditionToNumber/get'
}

export const CALL_CENTER_THUNKS_TYPES = {
  streets: 'callCenterStreets/get',
  numbers: 'callCenterStreetNum/get',
  additionToNumber: 'callCenterAddition/get',
  secondAddition: 'callCenterSecondAddition/get'
}