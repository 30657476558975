import { createSlice } from '@reduxjs/toolkit';
import { REFER_THUNKS_TYPES } from '../../data/constants';

const initialState = {
  data: [],
  isSecondAdditionLoading: false,
  errorSecondAdditionLoading: null,
  secondAdditionDisabled: true
}

const secondAdditionSlice = createSlice({
  name: 'secondAddition',
  initialState,
  reducers: {
    resetSecondAdditionData: (state, action) => {
      state.data = [];
      state.isSecondAdditionLoading = false;
      state.errorSecondAdditionLoading = null;
      state.secondAdditionDisabled = true;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(`${REFER_THUNKS_TYPES['secondAddition']}/pending`, (state, action) => {
      state.isSecondAdditionLoading = true;
      state.errorSecondAdditionLoading = null;
      state.secondAdditionDisabled = true;
    });
    builder.addCase(`${REFER_THUNKS_TYPES['secondAddition']}/fulfilled`, (state, action) => {
      state.isSecondAdditionLoading = false;
      state.data = action.payload;
      state.errorSecondAdditionLoading = null;
      state.secondAdditionDisabled = false;
    });
    builder.addCase(`${REFER_THUNKS_TYPES['secondAddition']}/rejected`, (state, action) => {
      state.isSecondAdditionLoading = false;
      state.errorSecondAdditionLoading = action.payload;
      state.secondAdditionDisabled = true;
    });
  }
});

export const { resetSecondAdditionData } = secondAdditionSlice.actions;
export const secondAdditionSliceReducer = secondAdditionSlice.reducer;