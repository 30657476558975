import { SUBMITTER_EMAIL, SUBMITTER_LASTNAME, SUBMITTER_NAME, SUBMITTER_PHONE, BUTTON_BACK, BUTTON_NEXT } from '../../data/constants';
import { Input, Button, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { changeData } from '../../features/infrastructure/call-center/submitterSlice';
import { setErrorMessage } from '../../features/errors/callCenterErrorSlice';

const Submitter = (props) => {
  const { activeKey } = useSelector(state => state.callCenterTabs);
  const { name, lastname, phone, email, address } = useSelector(state => state.submitter);
  const { message } = useSelector(state => state.callCenterError)

  const dispatch = useDispatch();

  const changeTabBack = () => {
    const newActiveKey = activeKey - 1;
    props.onTabChange(newActiveKey);
  }

  const changeTabNext = () => {
    const result = validateFields();
    if (!result.isValidate) {
      setError(`${result.errorField} је обавезно поље!`);
      return;
    }
    const newActiveKey = activeKey + 1;
    props.onTabChange(newActiveKey);
  }

  const validateFields = () => {
    let isValidate = true;
    let errorField;
    if (name === '') {
      isValidate = false;
      errorField = SUBMITTER_NAME
    } else if (lastname === '') {
      isValidate = false;
      errorField = SUBMITTER_LASTNAME
    } else if (phone === '') {
      isValidate = false;
      errorField = SUBMITTER_PHONE
    }

    return { isValidate, errorField }
  }
  const onNameChange = (e) => {
    dispatch(changeData({ property: 'name', value: e.target.value }))
  }
  const onLastnameChange = (e) => {
    dispatch(changeData({ property: 'lastname', value: e.target.value }))
  }
  const onEmailChange = (e) => {
    dispatch(changeData({ property: 'email', value: e.target.value }))
  }
  const onPhoneChange = (e) => {
    dispatch(changeData({ property: 'phone', value: e.target.value }))
  }
  const onAddressChange = (e) => {
    dispatch(changeData({ property: 'address', value: e.target.value }))
  }
  const setError = (error) => {
    dispatch(setErrorMessage(error));
    setTimeout(() => {
      dispatch(setErrorMessage(''));
    }, 4000);
  }
  return (
    <div>
      <div className='tabContentContainer'>
        <div style={{ width: '100%' }}>
          <div className='formGroup'>
            <label>{SUBMITTER_NAME}<span>*</span></label>
            <Input value={name} placeholder={SUBMITTER_NAME} className='formField' onChange={onNameChange} />
          </div>
          <div className='formGroup'>
            <label>{SUBMITTER_LASTNAME}<span>*</span></label>
            <Input value={lastname} placeholder={SUBMITTER_LASTNAME} className='formField' onChange={onLastnameChange} />
          </div>
          <div className='formGroup'>
            <label>{SUBMITTER_PHONE}<span>*</span></label>
            <Input value={phone} type='number' placeholder={SUBMITTER_PHONE} className='formField' onChange={onPhoneChange} />
          </div>
          <div className='formGroup'>
            <label>{SUBMITTER_EMAIL}</label>
            <Input value={email} type='email' placeholder={SUBMITTER_EMAIL} className='formField' onChange={onEmailChange} />
          </div>
          <div className='formGroup'>
            <label>Адреса за доставу одговора</label>
            <Input value={address} placeholder='Назив корисника' className='formField' onChange={onAddressChange} />
          </div>
        </div>
      </div >
      <div className='errorContainer errorTab2'>
        <Alert style={{ display: `${message !== '' ? 'block' : 'none'}` }} message={message} type="error" />
      </div>
      <div className='tab_1_buttonsContainer'>
        <Button onClick={changeTabBack} className='btnBack'>{BUTTON_BACK}</Button>
        <Button onClick={changeTabNext} className='btnNext'>{BUTTON_NEXT}</Button>
      </div>
    </div>
  )
}

export default Submitter;