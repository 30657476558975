import { createSlice } from '@reduxjs/toolkit';
import { getSpacesByAddress } from '../../../thunks/callCenterThunks';


const initialState = {
  spaceTypes: [],
  isSpacesLoading: false,
  isSpacesError: null,
}

const spaceTypesByAddressSlice = createSlice({
  name: 'spaceTypesByAddress',
  initialState,
  reducers: {
    resetSpaceTypes: (state, action) => {
      state.spaceTypes = [];
      state.isSpacesLoading = false;
      state.isSpacesError = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSpacesByAddress.pending, (state, action) => {
      state.isSpacesLoading = true;
      state.isSpacesError = null;
    });
    builder.addCase(getSpacesByAddress.fulfilled, (state, action) => {
      state.isSpacesLoading = false;
      state.spaceTypes = action.payload;
      state.isSpacesError = null;
    });
    builder.addCase(getSpacesByAddress.rejected, (state, action) => {
      state.isSpacesLoading = false;
      state.isSpacesError = action.payload;
    });
  }
});
export const { resetSpaceTypes } = spaceTypesByAddressSlice.actions;
export const spaceTypesByAddressReducer = spaceTypesByAddressSlice.reducer;