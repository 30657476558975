import { createSlice } from '@reduxjs/toolkit';

import { checkDamagesByIdent } from '../../../../thunks/callCenterThunks';

const initialState = {
  damages: null,
  isDamagesLoading: false,
  damagesError: null
}

const damagesByIdentSlice = createSlice({
  name: 'damagesByIdent',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(checkDamagesByIdent.pending, (state, action) => {
      state.isDamagesLoading = true;
      state.damagesError = null;
    });
    builder.addCase(checkDamagesByIdent.fulfilled, (state, action) => {
      state.isDamagesLoading = false;
      state.damages = action.payload;
      state.damagesError = null;
    });
    builder.addCase(checkDamagesByIdent.rejected, (state, action) => {
      state.isDamagesLoading = false;
      state.damagesError = action.payload;
    });
  }
});


export const damagesByIdentReducer = damagesByIdentSlice.reducer;