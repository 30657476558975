import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  lastname: '',
  email: '',
  phone: '',
  address: ''
}

const submitterSlice = createSlice({
  name: 'submitter',
  initialState,
  reducers: {
    changeData: (state, action) => {
      state[action.payload.property] = action.payload.value;
    },
    resetData: (state, action) => {
      state.name = '';
      state.lastname = '';
      state.email = '';
      state.phone = '';
      state.address = '';
    }
  }
});

export const { changeData, resetData } = submitterSlice.actions;
export const submitterReducer = submitterSlice.reducer;