import { Button, Alert } from 'antd';
import { BUTTON_NEXT, SPACE_LABEL, SPACE_TYPE, STREET, STREET_NUMBER } from '../../data/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import SpaceDataAddress from './SpaceDataAddress';
import SpaceDataIdent from './SpaceDataIdent';
import { setErrorMessage } from '../../features/errors/callCenterErrorSlice';
import { getStreets } from '../../thunks/infrastructure';
import { setActiveAddress, setActiveIdent } from '../../features/infrastructure/call-center/ident/activeIdentSlice';

const TabSpaceData = (props) => {
  const { activeKey } = useSelector(state => state.callCenterTabs);
  const { street, number, spaceType, spaceLabel } = useSelector(state => state.callCenterInfraData);
  const identData = useSelector(state => state.identFormData)
  const { message } = useSelector(state => state.callCenterError)
  const { isIdentActive, isAdressActive } = useSelector(state => state.activeIdent)
  // switch buttons
  // const [isIdentActive, setIdentBtn] = useState(true);
  // const [isAdressActive, setAddressBtn] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStreets('BG'))
  }, [dispatch])

  const changeTabNext = () => {
    if (!isIdentActive) {
      const result = validateAddressFields();
      if (!result.isValidate) {
        setError(`${result.errorField} је обавезно поље!`);
        return;
      }
    }
    else {
      const result = validateIdentFields();
      if (!result.isValidate) {
        setError(`${result.errorField} је обавезно поље!`);
        return;
      }
    }


    const newActiveKey = activeKey + 1;
    props.onTabChange(newActiveKey);
  }

  const onIdentClick = () => {
    if (!isIdentActive) {
      //setIdentBtn(true);
      dispatch(setActiveIdent(true))
    }
    if (isAdressActive) {
      //setAddressBtn(false);
      dispatch(setActiveAddress(false))
    }
  }

  const onAddressBtnClick = () => {
    if (!isAdressActive) {
      //setAddressBtn(true)
      dispatch(setActiveAddress(true))
    }
    if (isIdentActive) {
      //setIdentBtn(false)
      dispatch(setActiveIdent(false));
    }
  }

  const setError = (error) => {
    dispatch(setErrorMessage(error));
    setTimeout(() => {
      dispatch(setErrorMessage(''));
    }, 4000);
  }

  const validateAddressFields = () => {
    let isValidate = true;
    let errorField;
    if (street === '') {
      isValidate = false;
      errorField = STREET
    } else if (number === '') {
      isValidate = false;
      errorField = STREET_NUMBER
    } else if (spaceType === '') {
      isValidate = false;
      errorField = SPACE_TYPE
    } else if (spaceLabel === '') {
      isValidate = false;
      errorField = SPACE_LABEL
    }

    return { isValidate, errorField }
  }
  const validateIdentFields = () => {
    let isValidate = true;
    let errorField;
    if (identData.streetName === '') {
      isValidate = false;
      errorField = STREET
    } else if (identData.number === '') {
      isValidate = false;
      errorField = STREET_NUMBER
    } else if (identData.spaceType === '') {
      isValidate = false;
      errorField = SPACE_TYPE
    } else if (identData.spaceLabel === '') {
      isValidate = false;
      errorField = SPACE_LABEL
    }

    return { isValidate, errorField }
  }

  return (
    <>
      <div className='tabContentContainer_2'>
        {/* <div className='ident_container'>
          <label>Претрага по ИДЕНТ-у: </label>
          <Switch checked={checked} onChange={onSwitch} />
        </div> */}
        <div className='buttonsContainer'>
          <span style={{ fontWeight: 500 }}>Изабери начин претраге</span>
          <div style={{ marginTop: '0.5rem', paddingBottom: '1rem' }}>
            <Button
              onClick={onIdentClick}
              style={{ backgroundColor: `${isIdentActive ? '#3699FF' : ''}` }}
              type={isIdentActive ? 'primary' : 'default'}
              className='btnSearchIdent'>
              Претрага по ИДЕНТ-у
            </Button>
            <Button
              onClick={onAddressBtnClick}
              style={{ backgroundColor: `${isAdressActive ? '#3699FF' : ''}` }}
              type={isAdressActive ? 'primary' : 'default'}>
              Претрага по адреси
            </Button>
          </div>

        </div>
        {isAdressActive ? <SpaceDataAddress /> : <SpaceDataIdent />}
      </div>
      <div className='errorContainerCallCenter'>
        <Alert style={{ display: `${message !== '' ? 'block' : 'none'}` }} message={message} type="error" />
      </div>
      <div className='callCenterTab1Container tab_1_buttonsContainer'>
        <Button onClick={changeTabNext} className='btnNext'>{BUTTON_NEXT}</Button>
      </div>
    </>
  )
}

export default TabSpaceData;