import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Select, Button, Upload, Alert } from 'antd';
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { referTypeSelectionData } from '../data/data';
import { ACCEPTED_FILE_TYPE, BUTTON_BACK, BUTTON_NEXT, ERROR_UNSUPPORTED_FILE, REFER_DESCRIPTION, REFER_SUBTYPE, REFER_TITLE, REFER_TYPE, ERROR_UPLOAD_FILE_SIZE, UPLOAD_DESCRIPTION } from '../data/constants';
import { changeInputData, resetReferTypeData, changeFileList } from '../features/refers/referTypeSlice';
import { setErrorMessage } from '../features/errors/errorSlice';



const { TextArea } = Input;

const TabReferType = (props) => {
  const { selectedItemId } = useSelector(state => state.referClass);
  const { activeKey } = useSelector((state) => state.tabs);
  const { type, subtype, title, description, files } = useSelector((state) => state.referType);
  const { message } = useSelector(state => state.error)

  const dispatch = useDispatch();

  const [subtypeOptions, setSybtypeOptions] = useState([]);
  const [refTypeDesc, setDescription] = useState('');
  const [isDisable, setDisable] = useState(false)

  const referTypeData = referTypeSelectionData
    .filter(item => item.referId === selectedItemId)[0].data;

  const referTypeOptions = referTypeData.map((item) => {
    return { value: item.nameLatin, label: item.name }
  });



  useEffect(() => {
    const filterData = referTypeData.filter(item => item.nameLatin === type);
    // reset fields
    if (filterData.length === 0) {
      setDescription('');
      dispatch(resetReferTypeData());
    }
    if (type === '' || subtypeOptions.length === 0) {
      setDisable(true)
    } else {
      setDisable(false);
    }

  }, [selectedItemId, type, subtypeOptions, referTypeData, dispatch])

  const onReferTypeChange = (value) => {
    const referType = referTypeData.filter(item => item.nameLatin === value)[0];
    dispatch(changeInputData({ property: 'type', value: `${referType.nameLatin}` }));

    const description = referType.description;
    setSybtypeOptions(referType.subtypeData);
    setDescription(description);
  }

  const onReferSubtypeChange = (value) => {
    dispatch(changeInputData({ property: 'subtype', value: value }));
  }

  const onTitleChange = (e) => {
    dispatch(changeInputData({ property: 'title', value: e.target.value }));
  }
  const onDescChange = (e) => {
    dispatch(changeInputData({ property: 'description', value: e.target.value }));
  }

  const changeTabBack = () => {
    const newActiveKey = activeKey - 1;
    props.onTabChange(newActiveKey);
  }

  const changeTabNext = () => {
    const result = validateFields();
    if (!result.isValidate) {
      setError(`${result.errorField} је обавезно поље!`);
      return;
    }
    const newActiveKey = activeKey + 1;
    props.onTabChange(newActiveKey);
  }

  const validateFields = () => {
    let isValidate = true;
    let errorField;
    if (type === '') {
      isValidate = false;
      errorField = REFER_TYPE
    } else if (subtype === '' && subtypeOptions.length > 0) {
      isValidate = false;
      errorField = REFER_SUBTYPE
    } else if (title === '') {
      isValidate = false;
      errorField = REFER_TITLE
    } else if (description === '') {
      isValidate = false;
      errorField = REFER_DESCRIPTION
    }

    return { isValidate, errorField }
  }

  const uploadProps = {
    onChange: ({ fileList }) => {
      dispatch(changeFileList(fileList.filter(file => file.status !== 'error')));
    },
    onRemove: (file) => {
      const index = files.indexOf(file);
      const newFileList = [...files];
      newFileList.splice(index, 1);
      dispatch(changeFileList(newFileList))
    },
    beforeUpload: (file) => {
      if (!ACCEPTED_FILE_TYPE.includes(file.type)) {
        setError(`${ERROR_UNSUPPORTED_FILE} ${file.name}`);
        return;
      }

      let currentSize = 0;
      files.forEach(file => currentSize += file.size / 1024 / 1024);
      if ((file.size / 1024 / 1024 + currentSize) > 6) {
        setError(`${ERROR_UPLOAD_FILE_SIZE}`);
        return;
      }
      return false;
    },
    fileList: files,
    multiple: true,
    accept: '.pdf,.jpeg,.jpg,.png'
  };

  const setError = (error) => {
    dispatch(setErrorMessage(error));
    setTimeout(() => {
      dispatch(setErrorMessage(''));
    }, 4000);
  }
  return (
    <div>
      <div className='tabContentContainer'>
        <div style={{ width: '100%' }}>
          <div className='formGroup'>
            <label>{REFER_TYPE}<span>*</span></label>
            <Select value={type} className='formField' onChange={onReferTypeChange} options={referTypeOptions} />
            <div style={{ display: `${refTypeDesc === '' ? 'none' : 'block'}` }} className='selectedDesc'>{refTypeDesc}</div>
          </div>
          <div className='formGroup'>
            <label>{REFER_SUBTYPE}{subtypeOptions.length > 0 && <span>*</span>}</label>
            <Select value={subtype} disabled={isDisable} className='formField' options={subtypeOptions} onChange={onReferSubtypeChange} />
          </div>
          <div className='formGroup'>
            <label>{REFER_TITLE}<span>*</span></label>
            <Input value={title} placeholder={REFER_TITLE} className='formField' onChange={onTitleChange} />
          </div>
          <div className='formGroup'>
            <label>{REFER_DESCRIPTION}<span>*</span></label>
            <TextArea value={description} placeholder={REFER_DESCRIPTION} rows={3} className='formField' onChange={onDescChange} />
          </div>
          <div className='formGroup'>
            <label>Прилог документ</label>
            <div className='uploadContainer'>
              <Upload {...uploadProps}>
                <Button icon={<FontAwesomeIcon icon={faArrowUpFromBracket} />}>Одабери</Button>
                <div className='selectedDesc'>{UPLOAD_DESCRIPTION}</div>
              </Upload>
            </div>
          </div>
        </div>
      </div >
      <div className='errorContainer errorTab2'>
        <Alert style={{ display: `${message !== '' ? 'block' : 'none'}` }} message={message} type="error" />
      </div>
      <div className='tab_1_buttonsContainer'>
        <Button onClick={changeTabBack} className='btnBack'>{BUTTON_BACK}</Button>
        <Button onClick={changeTabNext} className='btnNext'>{BUTTON_NEXT}</Button>
      </div>
    </div>

  )
}

export default TabReferType;