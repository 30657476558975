import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  streetId: '',
  streetName: '',
  number: '',
  additionToNumber: '',
  secondAddition: '',
  spaceType: '',
  spaceLabel: '',
  object: '',
  ident: ''
}

const identFormDataSlice = createSlice({
  name: 'identFormData',
  initialState,
  reducers: {
    resetIdentFormData: (state, action) => {
      state.number = '';
      state.additionToNumber = '';
      state.secondAddition = '';
      state.spaceType = '';
      state.spaceLabel = '';
      state.streetId = '';
      state.streetName = ''
      state.object = '';
      state.ident = '';
    },
    resetSpaceTypeAndLabel: (state, action) => {
      state.spaceLabel = '';
      state.spaceType = '';
    },
    changeObjectData: (state, action) => {
      const data = action.payload;
      state.streetId = data.streetId;
      state.streetName = data.streetName;
      state.number = data.streetNumber;
      state.additionToNumber = data.subnumber;
      state.secondAddition = data.subnumber_2;
      state.object = data.object;
    },
    changeSpaceType: (state, action) => {
      state.spaceType = action.payload
    },
    changeSpaceLabel: (state, action) => {
      state.spaceLabel = action.payload
    },
    changeIdent: (state, action) => {
      state.ident = action.payload
    }
  }
});

export const {
  resetIdentFormData,
  changeSpaceType,
  changeSpaceLabel,
  changeObjectData,
  changeIdent,
  resetSpaceTypeAndLabel
} = identFormDataSlice.actions;

export const identFormDataReducer = identFormDataSlice.reducer;