import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  type: '',
  subtype: '',
  title: '',
  description: '',
  files: []
}

const referTypeSlice = createSlice({
  name: 'referType',
  initialState,
  reducers: {
    changeInputData: (state, action) => {
      state[action.payload.property] = action.payload.value;
    },
    changeFileList: (state, action) => {
      state.files = action.payload;
    },
    resetReferTypeData: (state, action) => {
      state.type = '';
      state.subtype = '';
      state.title = '';
      state.description = '';
      state.files = [];
    }
  }
});

export const { changeInputData, resetReferTypeData, changeFileList } = referTypeSlice.actions;
export const referTypeReducer = referTypeSlice.reducer;