import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  typeOptions: [],
  labelOptions: [],
  labelsByType: null
}

const spaceOptionSlice = createSlice({
  name: 'spaceOption',
  initialState,
  reducers: {
    changeTypeOptions: (state, action) => {
      state.typeOptions = action.payload;
    },
    changeLabelOptions: (state, action) => {
      state.labelOptions = action.payload;
    },
    changeLabelsByType: (state, action) => {
      state.labelsByType = action.payload;
    }
  }
});

export const { changeTypeOptions, changeLabelOptions, changeLabelsByType } = spaceOptionSlice.actions;
export const spaceOptionsReducer = spaceOptionSlice.reducer;