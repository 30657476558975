import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';

const ReferClass = (props) => {
  const { selectType, data, id } = props;
  const { selectedItemId } = useSelector((state) => state.referClass)

  const onSelect = () => {
    selectType(data.id);
  }

  return (
      <>
        <div onClick={onSelect} className={`singleReferType ${id === 1 ? 'disable-item' :''}`}>
          <div className={`referTypeContent ${data.id === selectedItemId ? 'isActiveType' : ''}`}>
            <Tooltip color='white' title={
              <div>
                <h4 className='tooltipHeadingText'>{data.tooltipHeading}</h4>
                <p className='tooltipText'>{data.tooltipText}</p>
              </div>}
            >
              <div className={`tooltipIcon ${data.id === selectedItemId ? 'isActiveIcon' : ''}`}>
                <FontAwesomeIcon icon={faCircleQuestion} />
              </div>
            </Tooltip>
            <FontAwesomeIcon className={`referTypeIcon ${data.id === selectedItemId ? 'isActiveIcon' : ''}`} icon={data.icon} />
            <div style={{ display: 'flex', flexDirection:'column' }}>
              <h4 className={`referTypeName ${data.id === selectedItemId ? 'isActiveIcon' : ''}`}>
                {data.name}
              </h4>
              {id === 1 && <span className={'disable-item-text'}>Привремено онемогућено</span>}
            </div>
          </div>

        </div>

      </>

  )
}


export default ReferClass;