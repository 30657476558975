import { Input, Select, Button, Spin, Alert } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactRecaptcha3 from 'react-google-recaptcha3';


import { BUTTON_BACK, STREET, STREET_NUMBER, SPACE_TYPE, SPACE_LABEL } from '../data/constants';
import { spaceTypeData, referClassItems } from '../data/data';
import { getStreets, getStreetNumbers, getAdditionsToNumbers, getSecondAddition, sendData } from '../thunks/infrastructure';
import { changeAdditionToNumber, changeNumber, changeSecondAddition, changeSpaceLabel, changeSpaceType, changeStreet, resetInfraData } from '../features/infrastructure/infraDataSlice';
import { setErrorMessage } from '../features/errors/errorSlice';
import { resetAdditionsData } from '../features/infrastructure/additionSlice';
import { resetSecondAdditionData } from '../features/infrastructure/secondAdditionSlice';

const TabInfrastructure = (props) => {
  const { activeKey } = useSelector((state) => state.tabs);
  const { selectedItemId } = useSelector(state => state.referClass);
  const { type, subtype, title, description, files } = useSelector(state => state.referType);
  const { name, lastname, user, email, phone } = useSelector(state => state.referSubmitter);
  const { message } = useSelector(state => state.error);

  const { streets, isLoading, error } = useSelector(state => state.streets);
  const { numbers, isNumbersLoading, errorLoadingNumbers, disabled } = useSelector(state => state.streetNumbers);
  const { additions, isAdditionsLoading, errorAdditionsLoading, additionDisabled } = useSelector(state => state.additionsToNumber);
  const { data, isSecondAdditionLoading, errorSecondAdditionLoading, secondAdditionDisabled } = useSelector(state => state.secondAddition);
  const { street, number, additionToNumber, secondAddition, spaceType, spaceLabel } = useSelector(state => state.infraData);
  const { isSendingLoading, errorSending, responseMessage } = useSelector(state => state.salesForce);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStreets(null));

  }, [dispatch])

  useEffect(() => {
    dispatch(resetInfraData())
  }, [dispatch, selectedItemId])

  const options = streets.map((item) => {
    return { value: item.id_ulica, label: `${item.naziv} (${item.naselje_naziv})` }
  });
  const numbersOptions = numbers.map((item) => {
    return { value: item.kucni_broj, label: item.kucni_broj }
  });
  const additionsOptions = additions.map(item => {
    return { value: item.kucni_broj_dopuna1, label: item.kucni_broj_dopuna1 }
  });
  const secondAdditionOptins = data.map(item => {
    return { value: item.kucni_broj_dodatak2, label: item.kucni_broj_dodatak2 }
  })

  const onStreetChange = (value, option) => {
    dispatch(resetInfraData());
    dispatch(changeStreet(value));
    dispatch(getStreetNumbers({ streetId: value, param: null }));
    dispatch(resetAdditionsData());
    dispatch(resetSecondAdditionData());
  }

  const onNumberChange = (value) => {
    dispatch(getAdditionsToNumbers({ streetId: street, number: value, param: null }));
    dispatch(changeNumber(value));

  }

  const onAdditionChange = (value) => {
    dispatch(getSecondAddition({ streetId: street, number, subnumber: value, param: null }))
    dispatch(changeAdditionToNumber(value))
  }

  const onSecondAdditionChange = (value) => {
    //dispatch(getSecondAddition({ streetId: street, number, subnumber: value, param: null }))
    dispatch(changeSecondAddition(value));
  }

  const onSpaceTypeChange = (value) => {
    dispatch(changeSpaceType(value));
  };

  const onSpaceLabelChange = (e) => {
    dispatch(changeSpaceLabel(e.target.value));
  }
  const changeTabBack = () => {
    const newActiveKey = activeKey - 1;
    props.onTabChange(newActiveKey);
  }
  const getRecaptchaToken = async () => {
    try {
      const token = await ReactRecaptcha3.getToken();
      return token;
    } catch (error) {
      console.log(error)
    }
  }

  const onFormSubmit = async (e) => {
    e.preventDefault();

    const result = validateFields();
    if (!result.isValidate) {
      setError(`${result.errorField} је обавезно поље!`);
      return;
    }
    const token = await getRecaptchaToken();

    let formData = new FormData();

    formData.append('recaptchaToken', token);
    formData.append('Vrsta_Reklamacije__c', referClassItems.filter(item => item.id === selectedItemId)[0].nameLatin);
    formData.append('Tip_Reklamacije__c', type);
    formData.append('Podtip_Reklamacije__c', subtype);
    formData.append('Subject', title);
    formData.append('Description', description);
    formData.append('Ime__c', name);
    formData.append('Prezime__c', lastname);
    formData.append('Naziv_Kompanije__c', user);
    formData.append('SuppliedEmail', email);
    formData.append('Telefon__c', phone);
    formData.append('ID_Ulice__c', street);
    formData.append('Broj__c', number);
    formData.append('Dodatak_ku_nom_broju__c', secondAddition);
    formData.append('Dopuna_broja__c', additionToNumber);
    formData.append('Vrsta_prostora__c', spaceType);
    formData.append('Oznaka_prostora__c', spaceLabel);
    formData.append('disp_form', false);
    formData.append('Origin', 'Web');

    files.forEach((file) => {
      formData.append('files', file.originFileObj, file.originFileObj.name);
    })

    dispatch(sendData(formData));
  }

  const validateFields = () => {
    let isValidate = true;
    let errorField;
    if (street === '') {
      isValidate = false;
      errorField = STREET
    } else if (number === '') {
      isValidate = false;
      errorField = STREET_NUMBER
    } else if (spaceType === '') {
      isValidate = false;
      errorField = SPACE_TYPE
    } else if (spaceLabel === '') {
      isValidate = false;
      errorField = SPACE_LABEL
    }

    return { isValidate, errorField }
  }

  const setError = (error) => {
    dispatch(setErrorMessage(error));
    setTimeout(() => {
      dispatch(setErrorMessage(''));
    }, 4000);
  }

  if (error) {
    setError(error);

  } else if (errorLoadingNumbers) {
    setError(errorLoadingNumbers);

  } else if (errorAdditionsLoading) {
    setError(errorAdditionsLoading);
  }
  else if (errorSecondAdditionLoading) {
    setError(errorSecondAdditionLoading);
  }
  else if (errorSending) {
    setError(errorSending.msg);
  }

  return (
    <div>
      {isSendingLoading &&
        <div className='sendLoaderContainer'>
          <Spin style={{ display: 'block' }} size='large' tip='Слање у току...' />
        </div>
      }
      {
        !isSendingLoading && responseMessage &&
        <div className='successMessageContainer'>
          <Alert
            message="Успешно обраћање!"
            description={`${responseMessage.msg}`}
            type="success"
          />
        </div>

      }
      {
        !isSendingLoading && !responseMessage &&
        <form onSubmit={onFormSubmit}>
          <div className='tabContentContainer'>
            <div style={{ width: '100%' }}>
              <div className='formGroup'>
                <label>{STREET}<span>*</span></label>
                <div style={{ display: `block`, fontStyle: 'italic' }} className='selectedDesc'>Претрага улица ћирилица</div>
                {isLoading && <Spin style={{ display: 'block' }} tip='Учитавање у току...' />}
                {!isLoading && !error &&
                  <Select
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    showSearch
                    value={street}
                    options={options}
                    className='formField'
                    onChange={onStreetChange} />
                }
              </div>
              <div className='formGroup'>
                <label>{STREET_NUMBER}<span>*</span></label>
                {isNumbersLoading && <Spin style={{ display: 'block' }} tip='Учитавање у току...' />}
                {!isNumbersLoading && !errorLoadingNumbers &&
                  <Select
                    value={number}
                    options={numbersOptions}
                    disabled={disabled}
                    className='formField'
                    onChange={onNumberChange}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                    }
                  />
                }
              </div>
              <div className='formGroup'>
                <label>Допуна броја</label>
                {isAdditionsLoading && <Spin style={{ display: 'block' }} tip='Учитавање у току...' />}
                {!isAdditionsLoading && !errorAdditionsLoading &&
                  <Select value={additionToNumber} disabled={additionDisabled} className='formField' options={additionsOptions} onChange={onAdditionChange} />
                }
              </div>
              <div className='formGroup'>
                <label>Додатак кућном броју</label>
                {isSecondAdditionLoading && <Spin style={{ display: 'block' }} tip='Учитавање у току...' />}
                {!isAdditionsLoading && !errorSecondAdditionLoading &&
                  <Select value={secondAddition} disabled={secondAdditionDisabled} className='formField' options={secondAdditionOptins} onChange={onSecondAdditionChange} />
                }
              </div>
              <div className='formGroup'>
                <label>{SPACE_TYPE}<span>*</span></label>
                <Select value={spaceType} options={spaceTypeData} className='formField' onChange={onSpaceTypeChange} />
              </div>
              <div className='formGroup'>
                <label>{SPACE_LABEL}<span>*</span></label>
                <Input value={spaceLabel} className='formField' onChange={onSpaceLabelChange} />
                <div className='selectedDesc'>Потребно унети број стана и допуну ознаке</div>
              </div>
            </div>
          </div >
          <div className='errorContainer errorTab2'>
            <Alert style={{ display: `${message !== '' ? 'block' : 'none'}` }} message={message} type="error" />
          </div>
          <div className='tab_1_buttonsContainer'>
            <Button onClick={changeTabBack} className='btnBack'>{BUTTON_BACK}</Button>
            <Button htmlType='submit' className='btnSend'>ПОШАЉИ ОБРАЋАЊЕ</Button>
          </div>
        </form>
      }

    </div>
  )
}

export default TabInfrastructure;