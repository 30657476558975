import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Select, Button, Alert, Spin } from 'antd';
import ReactRecaptcha3 from 'react-google-recaptcha3';

import { callCenterReferTypes } from '../../data/data';
import { BUTTON_BACK, REFER_DESCRIPTION, REFER_SUBTYPE, REFER_TYPE, } from '../../data/constants';
import { setErrorMessage } from '../../features/errors/callCenterErrorSlice';
import { changeReferDescData } from '../../features/infrastructure/call-center/referDescSlice';
import { sendDataToSf } from '../../thunks/callCenterThunks';
import { resetSendingData } from '../../features/infrastructure/call-center/callCenterSfSlice';

const { TextArea } = Input;

const TabReferDesc = (props) => {
  const { activeKey } = useSelector((state) => state.callCenterTabs);
  const { message } = useSelector(state => state.callCenterError);
  const { type, subtype, description } = useSelector(state => state.referDesc);
  const { isIdentActive } = useSelector(state => state.activeIdent);
  const { street, number, additionToNumber, secondAddition, spaceType, spaceLabel } = useSelector(state => state.callCenterInfraData);
  const identFormData = useSelector(state => state.identFormData);
  const { name, lastname, email, phone, address } = useSelector(state => state.submitter);
  const { isSendingLoading, responseMessage } = useSelector(state => state.callCenterSf);

  const dispatch = useDispatch();

  const [subtypeOptions, setSybtypeOptions] = useState([]);

  const [isDisable, setDisable] = useState(true)

  const referTypeOptions = callCenterReferTypes.map((item) => {
    return { value: item.nameLatin, label: item.name }
  });

  const onReferTypeChange = (value) => {
    const referType = callCenterReferTypes.filter(item => item.nameLatin === value)[0];
    dispatch(changeReferDescData({ property: 'type', value: `${referType.nameLatin}` }));
    setSybtypeOptions(referType.subtypeData);
    setDisable(false);

    if (subtype) {
      dispatch(changeReferDescData({ property: 'subtype', value: '' }));
    }
  }

  const onReferSubtypeChange = (value) => {
    dispatch(changeReferDescData({ property: 'subtype', value: value }));
  }

  const onDescChange = (e) => {
    dispatch(changeReferDescData({ property: 'description', value: e.target.value }));
  }

  const changeTabBack = () => {
    const newActiveKey = activeKey - 1;
    props.onTabChange(newActiveKey);
  }


  const validateFields = () => {
    let isValidate = true;
    let errorField;
    if (type === '') {
      isValidate = false;
      errorField = REFER_TYPE
    } else if (subtype === '' && subtypeOptions.length > 0) {
      isValidate = false;
      errorField = REFER_SUBTYPE
    } else if (description === '') {
      isValidate = false;
      errorField = REFER_DESCRIPTION
    }

    return { isValidate, errorField }
  }


  const setError = (error) => {
    dispatch(setErrorMessage(error));
    setTimeout(() => {
      dispatch(setErrorMessage(''));
    }, 4000);
  }

  const getRecaptchaToken = async () => {
    try {
      const token = await ReactRecaptcha3.getToken();
      return token;
    } catch (error) {
      console.log(error)
    }
  }

  const onFormSubmit = async (e) => {
    e.preventDefault();

    const result = validateFields();
    if (!result.isValidate) {
      setError(`${result.errorField} је обавезно поље!`);
      return;
    }

    const token = await getRecaptchaToken();

    let formData = new FormData();

    formData.append('recaptchaToken', token);
    formData.append('Origin', 'Pozivni centar');
    formData.append('Vrsta_Reklamacije__c', 'Prijava kvara');
    formData.append('Tip_Reklamacije__c', type);
    formData.append('Podtip_Reklamacije__c', subtype);
    formData.append('Description', description);
    formData.append('Ime__c', name);
    formData.append('Prezime__c', lastname);
    formData.append('Adresa_podnosioca_zahteva_Pisarnica__c', address);
    formData.append('SuppliedEmail', email);
    formData.append('Telefon__c', phone);
    formData.append('disp_form', true);

    if (!isIdentActive) {
      formData.append('ID_Ulice__c', street);
      formData.append('Broj__c', number);
      formData.append('Dodatak_ku_nom_broju__c', secondAddition === null ? '' : secondAddition);
      formData.append('Dopuna_broja__c', additionToNumber === null ? '' : additionToNumber);

      formData.append('Vrsta_prostora__c', spaceType);
      formData.append('Oznaka_prostora__c', spaceLabel);
    }
    else {
      formData.append('ID_Ulice__c', identFormData.streetId);
      formData.append('Broj__c', identFormData.number);
      formData.append('Dodatak_ku_nom_broju__c', identFormData.secondAddition === null ? '' : identFormData.secondAddition);
      formData.append('Dopuna_broja__c', identFormData.additionToNumber === null ? '' : identFormData.additionToNumber);

      formData.append('Vrsta_prostora__c', identFormData.spaceType);
      formData.append('Oznaka_prostora__c', identFormData.spaceLabel);
    }

    dispatch(sendDataToSf(formData));

  }

  const goBack = () => {
    dispatch(resetSendingData())
    //props.onTabChange(1);
  }

  return (
    <div>
      {isSendingLoading &&
        <div className='sendLoaderContainer'>
          <Spin style={{ display: 'block' }} size='large' tip='Слање у току...' />
        </div>
      }

      {!isSendingLoading && responseMessage &&
        <div className='successMessageContainer'>
          <Alert
            message="Успешно обраћање!"
            description={`${responseMessage.msg}`}
            type="success"
          />
          <Button className='btnGoBack' onClick={goBack}>Нови квар за корисника</Button>
        </div>}
      {!isSendingLoading && !responseMessage &&
        <form onSubmit={onFormSubmit}>
          <div className='tabContentContainer'>
            <div style={{ width: '100%' }}>
              <div className='formGroup'>
                <label>{REFER_TYPE}<span>*</span></label>
                <Select value={type} className='formField' onChange={onReferTypeChange} options={referTypeOptions} />
              </div>
              <div className='formGroup'>
                <label>{REFER_SUBTYPE}{subtypeOptions.length > 0 && <span>*</span>}</label>
                <Select value={subtype} disabled={isDisable} className='formField' options={subtypeOptions} onChange={onReferSubtypeChange} />
              </div>
              <div className='formGroup'>
                <label>{REFER_DESCRIPTION}<span>*</span></label>
                <TextArea value={description} placeholder={REFER_DESCRIPTION} rows={3} className='formField' onChange={onDescChange} />
              </div>
            </div>
          </div >
          <div className='errorContainer errorTab2'>
            <Alert style={{ display: `${message !== '' ? 'block' : 'none'}` }} message={message} type="error" />
          </div>
          <div className='tab_1_buttonsContainer'>
            <Button onClick={changeTabBack} className='btnBack'>{BUTTON_BACK}</Button>
            <Button htmlType='submit' className='btnSend'>ПОШАЉИ ОБРАЋАЊЕ</Button>
          </div>
        </form>
      }

    </div>

  )
}

export default TabReferDesc;