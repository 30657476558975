import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  street: '',
  number: '',
  additionToNumber: '',
  secondAddition: '',
  spaceType: '',
  spaceLabel: '',
  object: ''
}

const callCenterInfraDataSlice = createSlice({
  name: 'callCenterInfraData',
  initialState,
  reducers: {
    resetInfraData: (state, action) => {
      state.number = '';
      state.additionToNumber = '';
      state.secondAddition = '';
      state.spaceType = '';
      state.spaceLabel = '';
      state.street = '';
      state.object = ''
    },
    changeStreet: (state, action) => {
      state.street = action.payload
    },
    changeNumber: (state, action) => {
      state.number = action.payload
    },
    changeAdditionToNumber: (state, action) => {
      state.additionToNumber = action.payload
    },
    changeSecondAddition: (state, action) => {
      state.secondAddition = action.payload
    },
    changeSpaceType: (state, action) => {
      state.spaceType = action.payload
    },
    changeSpaceLabel: (state, action) => {
      state.spaceLabel = action.payload
    },
    changeObject: (state, action) => {
      state.object = action.payload
    }
  }
});

export const {
  resetInfraData,
  changeStreet,
  changeNumber,
  changeAdditionToNumber,
  changeSecondAddition,
  changeSpaceType,
  changeSpaceLabel,
  changeObject
} = callCenterInfraDataSlice.actions;
export const callCenterInfraDataReducer = callCenterInfraDataSlice.reducer;