import { createSlice } from '@reduxjs/toolkit';
import { CALL_CENTER_THUNKS_TYPES } from '../../../data/constants';

const initialState = {
  streets: [],
  isLoading: false,
  error: null
}

const streetsBgSlice = createSlice({
  name: 'streetsBg',
  initialState,
  reducers: {
    resetStreetData: (state, action) => {
      state = initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(`${CALL_CENTER_THUNKS_TYPES['streets']}/pending`, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(`${CALL_CENTER_THUNKS_TYPES['streets']}/fulfilled`, (state, action) => {
      state.isLoading = false;
      state.streets = action.payload;
      state.error = null;
    });
    builder.addCase(`${CALL_CENTER_THUNKS_TYPES['streets']}/rejected`, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  }
});

export const { resetStreetData } = streetsBgSlice.actions;
export const streetsBgReducer = streetsBgSlice.reducer;