import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { sendData } from '../../thunks/infrastructure';

const initialState = {
  isSendingLoading: false,
  errorSending: null,
  responseMessage: ''
}

const salesForceSlice = createSlice({
  name: 'salesForceSlice',
  initialState,
  reducers: {
    resetSendingData: (state, action) => {
      state.isSendingLoading = false;
      state.errorSending = null;
      state.responseMessage = '';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(sendData.pending, (state, action) => {
      state.isSendingLoading = true;
      state.errorSending = null;
      state.responseMessage = '';
    });
    builder.addCase(sendData.fulfilled, (state, action) => {
      state.isSendingLoading = false;
      state.errorSending = null;
      state.responseMessage = action.payload;

    });
    builder.addCase(sendData.rejected, (state, action) => {
      state.isSendingLoading = false;
      state.errorSending = action.payload;
      state.responseMessage = '';
    });
  }
});

export const { resetSendingData } = salesForceSlice.actions;
export const salesForceSliceReducer = salesForceSlice.reducer;