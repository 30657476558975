import { createSlice } from '@reduxjs/toolkit';
import { act } from 'react-dom/test-utils';

const initialState = {
  isIdentActive: true,
  isAdressActive: false
}

const activeIdentSlice = createSlice({
  name: 'activeIdent',
  initialState,
  reducers: {
    setActiveIdent: (state, action) => {
      state.isIdentActive = action.payload;
    },
    setActiveAddress: (state, action) => {
      state.isAdressActive = action.payload;
    }
  },

});

export const { setActiveAddress, setActiveIdent } = activeIdentSlice.actions;
export const activeIdentReducer = activeIdentSlice.reducer;