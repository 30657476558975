import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: ''
}

const callCenterErrorSlice = createSlice({
  name: 'callCenterError',
  initialState,
  reducers: {
    setErrorMessage: (state, action) => {
      state.message = action.payload;
    }
  }
});

export const { setErrorMessage } = callCenterErrorSlice.actions;
export const callCenterErrorReducer = callCenterErrorSlice.reducer;