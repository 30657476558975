
import { Tabs } from 'antd';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { setActiveKey } from '../features/tabs/tabsSlice';
import TabReferClass from './TabReferClass';
import TabReferSubmitter from './TabReferSubmitter';
import TabReferType from './TabReferType';
import TabInfrastructure from './TabInfrastructure';

const TabsContainer = () => {
  const { activeKey } = useSelector((state) => state.tabs);
  const dispatch = useDispatch();

  const changeTab = (newActiveKey) => {
    const newItems = [...tabItems];
    newItems.map((item) => {
      return item.key === newActiveKey ? item.disabled = false : item.disabled = true;
    });

    dispatch(setActiveKey(newActiveKey));
    setItems(newItems);
  }

  const items = [
    {
      key: 1,
      label: `1. Врста обраћања`,
      children: <TabReferClass nextTab={changeTab} />,
      disabled: false

    },
    {
      key: 2,
      label: `2. Опис обраћања`,
      children: <TabReferType onTabChange={changeTab} />,
      disabled: true
    },
    {
      key: 3,
      label: `3. Подносилац`,
      children: <TabReferSubmitter onTabChange={changeTab} />,
      disabled: true
    },
    {
      key: 4,
      label: `4. Подаци o простору`,
      children: <TabInfrastructure onTabChange={changeTab} />,
      disabled: true
    }
  ];

  const [tabItems, setItems] = useState(items);

  return (
    <div className='tabsContainer'>
      <div className='tabsContent'>
        <div className='formTitleContainer'>
          <h3>Унос обраћања</h3>
        </div>
        <Tabs centered={true} activeKey={activeKey} items={tabItems} />
      </div>

    </div>
  )
}

export default TabsContainer;