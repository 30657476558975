import { createSlice } from '@reduxjs/toolkit';
import { CALL_CENTER_THUNKS_TYPES } from '../../../data/constants';

const initialState = {
  additions: [],
  isAdditionsLoading: false,
  errorAdditionsLoading: null,
  additionDisabled: true
}

const subnumberSlice = createSlice({
  name: 'callCenterAdditions',
  initialState,
  reducers: {
    resetAdditionsData: (state, action) => {
      state.additions = [];
      state.isAdditionsLoading = false;
      state.errorAdditionsLoading = null;
      state.additionDisabled = true;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(`${CALL_CENTER_THUNKS_TYPES['additionToNumber']}/pending`, (state, action) => {
      state.isAdditionsLoading = true;
      state.errorAdditionsLoading = null;
      state.additionDisabled = true;
    });
    builder.addCase(`${CALL_CENTER_THUNKS_TYPES['additionToNumber']}/fulfilled`, (state, action) => {
      state.isAdditionsLoading = false;
      state.additions = action.payload;
      state.errorAdditionsLoading = null;
      state.additionDisabled = false;
    });
    builder.addCase(`${CALL_CENTER_THUNKS_TYPES['additionToNumber']}/rejected`, (state, action) => {
      state.isAdditionsLoading = false;
      state.errorAdditionsLoading = action.payload;
      state.additionDisabled = true;
    });
  }
});

export const { resetAdditionsData } = subnumberSlice.actions;
export const callCenterAdditionsReducer = subnumberSlice.reducer;