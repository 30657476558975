import { useDispatch, useSelector } from 'react-redux';
import { getStreetNumbers, getAdditionsToNumbers, getSecondAddition } from '../../thunks/infrastructure';
import { changeAdditionToNumber, changeNumber, changeObject, changeSecondAddition, changeSpaceLabel, changeSpaceType, changeStreet, resetInfraData } from '../../features/infrastructure/call-center/callCenterInfraDataSlice';
import { Button, Input, Select, Spin, Modal, Table } from 'antd';
import lodash from 'lodash';

import { checkDamagesByAddress, getBuilding, getSpacesByAddress } from '../../thunks/callCenterThunks';

import { SPACE_LABEL, SPACE_TYPE, STREET, STREET_NUMBER, STREET_SUBNUMBER, STREET_SUBNUMBER_2, } from '../../data/constants';
import { useEffect, useState } from 'react';
import { changeLabelOptions, changeLabelsByType, changeTypeOptions, resetSpaceOptions } from '../../features/infrastructure/call-center/spaceOptionsByAddressSlice';
import { setErrorMessage } from '../../features/errors/callCenterErrorSlice';
import { resetAdditionsData } from '../../features/infrastructure/call-center/subnumberSlice';
import { resetSecondAdditionData } from '../../features/infrastructure/call-center/subnumber2Slice';
import { resetSpaceTypes } from '../../features/infrastructure/call-center/spaceTypesByAddressSlice';
import { resetBuildingData } from '../../features/infrastructure/call-center/buildingSlice';

const SpaceDataAddress = () => {
  const { streets } = useSelector(state => state.callCenterStreets);
  const { numbers, isNumbersLoading, errorLoadingNumbers, disabled } = useSelector(state => state.callCenterStreetNum);
  const { additions, isAdditionsLoading, errorAdditionsLoading, additionDisabled } = useSelector(state => state.callCenterAdditionsToNum);
  const { data, isSecondAdditionLoading, errorSecondAdditionLoading, secondAdditionDisabled } = useSelector(state => state.callCenterSecondAdditions);
  const { street, number, additionToNumber, secondAddition, object, spaceType, spaceLabel } = useSelector(state => state.callCenterInfraData);
  const { buildingData } = useSelector(state => state.building);
  const { spaceTypes } = useSelector(state => state.spaceTypesByAddress);
  const { typeOptions, labelOptions, labelsByType } = useSelector(state => state.spaceOptionsByAddress);
  const { damages, isDamagesLoading, damagesError } = useSelector(state => state.damagesByAddress);

  const [poremecajiDataSource, setPoremecajiDataSource] = useState([]);
  const [predmetiDataSource, setPredmetiDataSource] = useState([]);

  const [brojPoremecaja, setBrojPoremecaja] = useState(0);
  const [brojKvarova, setBrojKvarova] = useState(0);

  const [visible, setVisible] = useState('none');
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (buildingData && buildingData.id_objekat !== object) {
      dispatch(changeObject(buildingData.id_objekat));

      let obj = {
        streetId: street,
        streetNumber: number,
        subnumber: additionToNumber === '' ? null : additionToNumber,
        subnumber_2: secondAddition === '' ? null : secondAddition
      };
      dispatch(getSpacesByAddress(obj));
    }
  }, [buildingData]);

  useEffect(() => {
    if (spaceTypes.length > 0) {
      createOptions();
    }
  }, [spaceTypes]);

  useEffect(() => {
    if (damages) {
      createDataForModal();
      setVisible('block')
    }

  }, [damages]);

  const columns = [
    {
      title: 'Број предмета',
      dataIndex: 'brojPredmeta',
      key: 'brojPredmeta',
    },
    {
      title: 'Подносилац',
      dataIndex: 'podnosilac',
      key: 'podnosilac',
    },
    {
      title: 'Датум и време пријаве',
      dataIndex: 'datumPrijave',
      key: 'datumPrijave',
    },
  ];

  const createDataForModal = () => {
    const grouped = lodash.groupBy(damages, 'Record_Type_Formula__c');
    const regularniPredmeti = grouped['Regularni predmet'];
    const poremecaji = grouped['Poremećaj'];

    let dataSourcePredmeti = [];
    let dataSourcePoremecaji = [];

    if (regularniPredmeti) {
      dataSourcePredmeti = regularniPredmeti.map((item, index) => {
        return {
          key: index,
          brojPredmeta: item.CaseNumber,
          podnosilac: `${item.Ime__c} ${item.Prezime__c}`,
          datumPrijave: formatDatumPrijave(item.CreatedDate)
        }
      });
    }
    if (poremecaji) {
      dataSourcePoremecaji = poremecaji.map((item, index) => {
        return {
          key: index,
          brojPredmeta: item.CaseNumber,
          podnosilac: `${item.Ime__c} ${item.Prezime__c}`,
          datumPrijave: formatDatumPrijave(item.CreatedDate)
        }
      });
    }

    setPredmetiDataSource(dataSourcePredmeti);
    setBrojKvarova(dataSourcePredmeti.length);

    setPoremecajiDataSource(dataSourcePoremecaji);
    setBrojPoremecaja(dataSourcePoremecaji.length);
  }

  const formatDatumPrijave = (stringDate) => {
    // stringDate yyyy-mm-ddThh:mm:ss.00+0000
    const splitted = stringDate.split('T');
    const date = splitted[0];
    const time = splitted[1].slice(0, 8);
    const splittedTime = time.split(':');
    // add 2 hours to current time value
    const timeString = splittedTime[0];
    const timeNum = Number(timeString);
    const newTimeNum = timeNum + 2;
    const zero = newTimeNum.toString().length > 1 ? '' : '0';
    const newStringTime = `${zero}${newTimeNum}:${splittedTime[1]}:${splittedTime[2]}`

    return `${date} ${newStringTime}`

  }

  const streetOptions = streets.map((item) => {
    return { value: item.id_ulica, label: `${item.naziv} (${item.naselje_naziv})` }
  });
  const numbersOptions = numbers.map((item) => {
    return { value: item.kucni_broj, label: item.kucni_broj }
  });
  const additionsOptions = additions.map(item => {
    return { value: item.kucni_broj_dopuna1, label: item.kucni_broj_dopuna1 }
  });
  const secondAdditionOptins = data.map(item => {
    return { value: item.kucni_broj_dodatak2, label: item.kucni_broj_dodatak2 }
  });

  const createOptions = () => {
    const spaceOptions = [];
    const labelsByType = {};

    const grouped = lodash.groupBy(spaceTypes, 'vrsta_prostora_id');
    for (const [key, value] of Object.entries(grouped)) {
      spaceOptions.push({ value: key, label: value[0].vrsta_prostora });

      let labelArray = [];
      value.map((item) => {
        labelArray.push({ value: item.id_prostor, label: `${item.id_prostor} (${item.oznaka} - ${item.dopuna_oznake})` })
      });

      labelsByType[key] = labelArray;
    }
    dispatch(changeTypeOptions(spaceOptions))
    dispatch(changeLabelsByType(labelsByType));
  }

  const onStreetChange = (value) => {
    dispatch(resetInfraData());
    dispatch(changeStreet(value));
    setVisible('none');
    dispatch(getStreetNumbers({ streetId: value, param: 'BG' }));
    // reset values
    dispatch(resetAdditionsData());
    dispatch(resetSecondAdditionData());
    dispatch(resetSpaceTypes());
    dispatch(resetSpaceOptions());
    dispatch(resetBuildingData());
  }
  const onNumberChange = (value) => {
    dispatch(getAdditionsToNumbers({ streetId: street, number: value, param: 'BG' }));
    dispatch(changeNumber(value));
  }
  const onAdditionChange = (value) => {
    dispatch(getSecondAddition({ streetId: street, number, subnumber: value, param: 'BG' }))
    dispatch(changeAdditionToNumber(value))
  }

  const findBuilding = async () => {
    if (!street || !number) {
      setError(`${STREET} и ${STREET_NUMBER} су обавезна поља за претрагу објекта!`);
      return;
    }
    let obj = {
      streetId: street,
      streetNumber: number,
      subnumber: additionToNumber === '' ? null : additionToNumber,
      subnumber_2: secondAddition === '' ? null : secondAddition
    };

    dispatch(getBuilding(obj));
  }

  const onSpaceTypeChange = (value, option) => {
    const labelOptions = labelsByType[value];
    dispatch(changeSpaceType(option.label));
    dispatch(changeLabelOptions(labelOptions))
  }

  const onSpaceLabelChange = (value) => {
    dispatch(changeSpaceLabel(value));
  }

  const checkRefers = () => {
    if (!object) {
      setError('Ознака објекта је обавезно поље за проверу обраћања!');
      return;
    }
    setVisible('block');

    let endDate = new Date();
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 4);

    let isoStart = startDate.toISOString().slice(0, 10);
    let isoEnd = endDate.toISOString().slice(0, 10);

    dispatch(checkDamagesByAddress({ objectId: object, startDate: isoStart, endDate: isoEnd }))
  }

  const onSecondAdditionChange = (value) => {
    dispatch(changeSecondAddition(value));
  }

  const setError = (error) => {
    dispatch(setErrorMessage(error));
    setTimeout(() => {
      dispatch(setErrorMessage(''));
    }, 4000);
  }


  return (
    <>
      <div className='left'>
        <div className='formGroup'>
          <label>{STREET}<span>*</span></label>
          <div style={{ display: `block`, fontStyle: 'italic' }} className='selectedDesc'>Претрага улица ћирилица</div>
          <Select
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            showSearch
            value={street}
            options={streetOptions}
            className='formField'
            onChange={onStreetChange} />

        </div>
        <div className='formGroup'>
          <label>{STREET_NUMBER}<span>*</span></label>
          {isNumbersLoading && <Spin style={{ display: 'block' }} tip='Учитавање у току...' />}
          {!isNumbersLoading && !errorLoadingNumbers &&
            <Select
              value={number}
              options={numbersOptions}
              disabled={disabled}
              className='formField'
              onChange={onNumberChange}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
              }
            />
          }
        </div>
        <div className='formGroup'>
          <label>{STREET_SUBNUMBER_2}</label>
          {isAdditionsLoading && <Spin style={{ display: 'block' }} tip='Учитавање у току...' />}
          {!isAdditionsLoading && !errorAdditionsLoading &&
            <Select value={additionToNumber} disabled={additionDisabled} className='formField' options={additionsOptions} onChange={onAdditionChange} />
          }
        </div>
        <div className='formGroup'>
          <label>{STREET_SUBNUMBER}</label>
          {isSecondAdditionLoading && <Spin style={{ display: 'block' }} tip='Учитавање у току...' />}
          {!isSecondAdditionLoading && !errorSecondAdditionLoading &&
            <Select value={secondAddition} disabled={secondAdditionDisabled} className='formField' options={secondAdditionOptins} onChange={onSecondAdditionChange} />
          }
        </div>
        <div className='formGroup'>
          <Button className='btnFindBuilding' onClick={findBuilding}>Пронађи објекат</Button>
        </div>

      </div>
      <div className='right' >
        <div style={{ marginTop: '1.5rem' }} className='formGroup'>
          <label>Ознака објекта</label>
          <Input style={{ color: 'rgba(0, 0, 0, 0.88)' }} value={object} className='formField' type='text' disabled={true} />
        </div>
        <div className='formGroup'>
          <label>{SPACE_TYPE}<span>*</span></label>
          <Select value={spaceType} options={typeOptions} className='formField' onChange={onSpaceTypeChange} />
        </div>
        <div className='formGroup'>
          <label>{SPACE_LABEL}<span>*</span></label>
          <Select value={spaceLabel} options={labelOptions} className='formField' onChange={onSpaceLabelChange} />
        </div>
        <div style={{ display: `${visible}` }} className='formGroup'>
          {isDamagesLoading && <Spin style={{ display: 'block' }} tip='Учитавање у току...' />}
          {damagesError && setError()}
          {
            !isDamagesLoading && !damagesError &&
            <div>
              <p>Број пријављених поремећаја је: {`${brojPoremecaja}`}</p>
              <p>Број пријављених кварова је: {`${brojKvarova}`}</p>
              <div style={{ color: '#3699FF', cursor: 'pointer' }} onClick={() => setModalOpen(true)}>{'[види све]'}</div>
            </div>
          }

        </div>
        <div className='formGroup'>
          <Button style={{ float: 'right' }} className='btnFindBuilding' onClick={checkRefers}>Провери обраћања</Button>
          <Modal
            className='modalContainer'
            title='Поремећаји и кварови'
            centered
            open={modalOpen}
            onCancel={() => setModalOpen(false)}
            footer={[
              <Button className='btnCloseModal' onClick={() => setModalOpen(false)}>Затвори</Button>
            ]}
          >
            <div className='modalBody'>
              <div className='modalRow'>
                <div className='poremecajiContainer'>
                  {brojPoremecaja === 0
                    ?
                    <h4>Није било поремећаја у последњих 5 дана</h4>
                    :
                    <div>
                      <h4>Поремећаји u poslednjih 5 dana</h4>
                      <Table dataSource={poremecajiDataSource} columns={columns} />
                    </div>
                  }
                </div>
                <div className='poremecajiContainer'>
                  {brojKvarova === 0
                    ?
                    <h4>Није било кварова у последњих 5 дана</h4>
                    :
                    <div>
                      <h4>Кварови u poslednjih 5 dana</h4>
                      <Table dataSource={predmetiDataSource} columns={columns} />
                    </div>
                  }
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  )
}

export default SpaceDataAddress;