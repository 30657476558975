import { createSlice } from '@reduxjs/toolkit';

import { getBuildingByIdent } from '../../../../thunks/callCenterThunks';

const initialState = {
  buildingIdentData: null,
  isBuildingIdentLoading: false,
  buildingIdentError: null
}

const buildingIdentSlice = createSlice({
  name: 'buildingIdent',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getBuildingByIdent.pending, (state, action) => {
      state.isBuildingIdentLoading = true;
      state.buildingIdentError = null;
    });
    builder.addCase(getBuildingByIdent.fulfilled, (state, action) => {
      state.isBuildingIdentLoading = false;
      state.buildingIdentData = action.payload;
      state.buildingIdentError = null;
    });
    builder.addCase(getBuildingByIdent.rejected, (state, action) => {
      state.isBuildingIdentLoading = false;
      state.buildingIdentError = action.payload;
    });
  }
});

export const buildingIdentReducer = buildingIdentSlice.reducer;