import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '../api/api';
import { CALL_CENTER_THUNKS_TYPES, REFER_THUNKS_TYPES } from '../data/constants';

const returnActionType = (key) => {
  const pathname = window.location.pathname;
  if (pathname === '/dispecerski-centar') {
    return CALL_CENTER_THUNKS_TYPES[key];
  } else if (pathname === '/unos-obracanja') {
    return REFER_THUNKS_TYPES[key];
  }
}

const getStreets = createAsyncThunk(
  returnActionType('streets'),
  async (param, { rejectWithValue }) => {
    try {
      const response = await api.get('/api/streets', {
        params: {
          municipality: param
        }
      });
      return response.data;
    } catch (error) {
      let errorValue;
      if (Object.hasOwn(error, 'response')) {
        errorValue = error.response.data;
      }
      else {
        errorValue = error.message;
      }
      return rejectWithValue(errorValue);
    }
  });

const getStreetNumbers = createAsyncThunk(
  returnActionType('numbers'),
  async ({ streetId, param }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/api/streets/${streetId}`, {
        params: {
          municipality: param
        }
      });
      return response.data;
    } catch (error) {
      let errorValue;
      if (Object.hasOwn(error, 'response')) {
        errorValue = error.response.data;
      }
      else {
        errorValue = error.message;
      }
      return rejectWithValue(errorValue);
    }
  }
)

const getAdditionsToNumbers = createAsyncThunk(
  returnActionType('additionToNumber'),
  async (obj, { rejectWithValue }) => {
    try {
      const response = await api.get(`/api/streets/${obj.streetId}/${obj.number}`, {
        params: {
          municipality: obj.param
        }
      });
      return response.data;
    } catch (error) {
      let errorValue;
      if (Object.hasOwn(error, 'response')) {
        errorValue = error.response.data;
      }
      else {
        errorValue = error.message;
      }
      return rejectWithValue(errorValue);
    }
  }
)

const getSecondAddition = createAsyncThunk(
  returnActionType('secondAddition'),
  async (obj, { rejectWithValue }) => {
    try {
      const response = await api.get(`/api/streets/${obj.streetId}/${obj.number}`, {
        params: {
          street_subnumber: obj.subnumber,
          municipality: obj.param
        }
      });
      return response.data;

    } catch (error) {
      let errorValue;
      if (Object.hasOwn(error, 'response')) {
        errorValue = error.response.data;
      }
      else {
        errorValue = error.message;
      }
      return rejectWithValue(errorValue);
    }
  }
)

const sendData = createAsyncThunk(
  'salesForce/sendData',
  async (obj, { rejectWithValue }) => {
    try {
      const response = await api.post(`/api/v1.0/salesforce/sobjects/case/`, obj, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;

    } catch (error) {
      let errorValue;
      if (Object.hasOwn(error, 'response')) {
        errorValue = error.response.data;
      }
      else {
        errorValue = error.message;
      }
      return rejectWithValue(errorValue);
    }
  }
)

export { getStreets, getStreetNumbers, getAdditionsToNumbers, getSecondAddition, sendData };