import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeKey: 1
}

const callCenterTabsSlice = createSlice({
  name: 'callCenterTabs',
  initialState,
  reducers: {
    setActiveKey: (state, action) => {
      state.activeKey = action.payload;
    }
  }
});

export const { setActiveKey } = callCenterTabsSlice.actions;
export const callCenterTabsReducer = callCenterTabsSlice.reducer;