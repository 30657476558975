import { createSlice } from '@reduxjs/toolkit';

import { REFER_THUNKS_TYPES } from '../../data/constants';

const initialState = {
  streets: [],
  isLoading: false,
  error: null,
  selectedStreet: ''
}

const streetSlice = createSlice({
  name: 'streets',
  initialState,
  reducers: {
    resetStreetData: (state, action) => {
      state = initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(`${REFER_THUNKS_TYPES['streets']}/pending`, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(`${REFER_THUNKS_TYPES['streets']}/fulfilled`, (state, action) => {
      state.isLoading = false;
      state.streets = action.payload;
      state.error = null;
    });
    builder.addCase(`${REFER_THUNKS_TYPES['streets']}/rejected`, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  }
});

export const { resetStreetData } = streetSlice.actions;
export const streetSliceReducer = streetSlice.reducer;