import { useEffect } from 'react';
import ReactRecaptcha3 from 'react-google-recaptcha3';

import LogoContainer from './components/LogoContainer';
import TabsContainer from './components/TabsContainer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CallCenterTabsContainer from './components/call-center/TabsCallCenter';

function App() {
  useEffect(() => {
    loadRecaptchaScript();
  }, [])

  const loadRecaptchaScript = async () => {
    const result = await ReactRecaptcha3.init(process.env.REACT_APP_RECAPTCHA_SITE_ID);
    // result: success/error
    // success - script is loaded and recaptcha is ready
    // error - script is not loaded
    console.log(result);
  }

  const DispCentar = () => <div>DISPECERSKI CENTAR</div>

  return (
    <>
      <BrowserRouter>
        <LogoContainer />
        <Routes>
          <Route path='/unos-obracanja' element={<TabsContainer />}></Route>
          <Route path='/dispecerski-centar' element={<CallCenterTabsContainer />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
