import { faWrench, faPenToSquare, faClipboardList, faFileLines, faFileSignature } from '@fortawesome/free-solid-svg-icons';

export const referClassItems = [
  {
    id: 1,
    name: 'Пријава квара',
    nameLatin: 'Prijava kvara',
    icon: faWrench,
    tooltipHeading: 'Овде можете пријавити:',
    tooltipText: 'Ако немате грејање или потрошну топлу воду, ако je температура испод прописане као и остале техничке проблеме.',
  },
  {
    id: 2,
    name: 'Рекламација',
    nameLatin: 'Reklamacija',
    icon: faPenToSquare,
    tooltipHeading: 'Овде можете уложити рекламацију на:',
    tooltipText: 'износ рачуна или на погрешне показатеље у рачуну.',
  },
  {
    id: 3,
    name: 'Жалба',
    nameLatin: 'Žalba',
    icon: faClipboardList,
    tooltipHeading: 'Овде можете уложити жалбу на:',
    tooltipText: 'поступање запослених, Решења о прикључењу или искључењу, рок поступања и на сам поступак.',
  },
  {
    id: 4,
    name: 'Захтев',
    nameLatin: 'Zahtev',
    icon: faFileLines,
    tooltipHeading: 'Овде можете поднети захтев за:',
    tooltipText: 'добијање информација, доставу документације, надокнаду штете, покретање процедура (прикључење, искључење, технички услови, репрограм, промена власништва, контрола прикључености, повраћај средстава...).',
  },
  {
    id: 5,
    name: 'Приговор',
    nameLatin: 'Prigovor',
    icon: faFileSignature,
    tooltipHeading: 'Овде можете уложити приговор на:',
    tooltipText: 'одговор на рекламацију или дуги документ.',
  }
];

export const referTypeSelectionData = [
  {
    referId: 1,
    data: [
      {
        name: 'Негрејање',
        nameLatin: 'Negrejanje',
        description: 'Ако нема грејања у целом објекту или делу објекта, целом простору или делу простора.',
        subtypeData: [
          {
            value: 'Ceo objekat ili deo objekta nema grejanje',
            label: 'Цео објекат или део објекта нема грејање'
          },
          {
            value: 'Ceo prostor ili deo prostora nema grejanje',
            label: 'Цео простор или део простора нема грејање'
          }

        ]
      },
      {
        name: 'Температура испод прописане',
        nameLatin: 'Temperatura ispod propisane',
        description: 'Ако је лоше грејање у простору или делу простора.',
        subtypeData: [
          {
            value: 'Loše grejanje u prostoru ili delu prostora',
            label: 'Лоше грејање у простору или делу простора'
          }

        ]
      },
      {
        name: 'Потрошна топла вода',
        nameLatin: 'Potrošna topla voda',
        description: 'Ако нема дотока, ако је неодговарајућа температура топле воде или је запрљана.',
        subtypeData: [
          {
            value: 'Nema dotoka tople vode',
            label: 'Нема дотока топле воде'
          },
          {
            value: 'Neodgovarajuća temperatura tople vode',
            label: 'Неодговарајућа температура топле воде'
          },
          {
            value: 'Prljava topla voda',
            label: 'Прљава топла вода'
          }
        ]
      },
      {
        name: 'Бука',
        nameLatin: 'Buka',
        description: '',
        subtypeData: [
          {
            value: 'Buka u podstanici',
            label: 'Бука у подстаници'
          }

        ]
      }

    ]
  },
  {
    referId: 2,
    data: [
      {
        name: 'На рачун',
        nameLatin: 'Na račun',
        description: 'Формална (адресни подаци, дуговања, камата, преплата и сл.), потрошња топлотне енергије, расподела топлотне енергије.',
        subtypeData: [
          {
            value: 'Formalna reklamacija',
            label: 'Формална рекламација'
          },
          {
            value: 'Potrošnja/Raspodela',
            label: 'Потрошња/Расподела'
          }
        ]
      },
      {
        name: 'На параметре услуге',
        nameLatin: 'Na parametre usluge',
        description: 'Погрешна идентификација простора, загревана површина, ангажована снага, врста обрачуна.',
        subtypeData: [
          {
            value: 'Na pogrešnu identifikaciju prostora',
            label: 'На погрешну идентификацију простора'
          },
          {
            value: 'Na zagrevanu površinu',
            label: 'На загревану површину'
          },
          {
            value: 'Na instalisanu snagu',
            label: 'На инсталисану снагу'
          },
          {
            value: 'Na vrstu obračuna',
            label: 'На врсту обрачуна'
          }
        ]
      }
    ]
  },
  {
    referId: 3,
    data: [
      {
        name: 'На квар који се понавља',
        nameLatin: 'Na kvar koji se ponavlja',
        description: '',
        subtypeData: []
      },
      {
        name: 'На грађевинско-техничке послове',
        nameLatin: 'Na građevinsko-tehničke poslove',
        description: '',
        subtypeData: [
          {
            value: 'Nezavršeni građevinski radovi',
            label: 'Незавршени грађевински радови'
          },
          {
            value: 'Nezavršeni zanatski radovi u zgradi',
            label: 'Незавршени занатски радови у згради'
          },
          {
            value: 'Problemi sa šahtovima na ulici',
            label: 'Проблеми са шахтовима на улици'
          }
        ]
      },
      {
        name: 'На поступање запослених',
        nameLatin: 'Na postupanje zaposlenih',
        description: 'Поступање запослених',
        subtypeData: []
      },
      {
        name: 'На решење о прикључењу/искључењу',
        nameLatin: 'Na rešenje o priključenju/isključenju',
        description: 'Решење о прикључењу/искључењу',
        subtypeData: []
      },
      {
        name: 'На рок поступања',
        nameLatin: 'Na rok postupanja',
        description: 'Рок поступања',
        subtypeData: []
      },
      {
        name: 'На поступак у предмету',
        nameLatin: 'Na postupak u predmetu',
        description: 'Поступак у предмету',
        subtypeData: []
      }
    ]
  },
  {
    referId: 4,
    data: [
      {
        name: 'Захтев за информацију',
        nameLatin: 'Zahtev za informaciju',
        description: 'Поступци и процедуре, стање прикључености, информације од јавног значаја.',
        subtypeData: [
          {
            value: 'Informacija od javnog značaja',
            label: 'Информација од јавног значаја'
          },
          {
            value: 'O postupcima i procedurama',
            label: 'О поступцима и процедурама'
          },
          {
            value: 'O stanju priključenosti',
            label: 'О стању прикључености'
          },
          {
            value: 'Ostalo',
            label: 'Остало'
          }
        ]
      },
      {
        name: 'Захтев за доставу документације',
        nameLatin: 'Zahtev za dostavu dokumentacije',
        description: 'Рачун, књижно одобрење, ИОС, уговор о испоруци, уговор о прикључењу.',
        subtypeData: [
          {
            value: 'Knjižno odobrenje',
            label: 'Књижно одобрење'
          },
          {
            value: 'Račun',
            label: 'Рачун'
          },
          {
            value: 'IOS',
            label: 'ИОС'
          },
          {
            value: 'Ugovor TE',
            label: 'Уговор ТЕ'
          },
          {
            value: 'Ugovor participacija',
            label: 'Уговор партиципација'
          },
          {
            value: 'Ostalo',
            label: 'Остало'
          }
        ]
      },
      {
        name: 'Захтев за покретање процедуре',
        nameLatin: 'Zahtev za pokretanje procedure',
        description: 'Издавање, обнова и измена техничких услова, преглед пројекта термо-техничке инсталације, предуговор о одобрењу прикључења, решење о одобрењу прикључења, уговор о недостајућој инфраструктури, пријава за прикључење/искључење, спајање/подела простора, промена параметара, раздвајање подстаница, контрола прикључености, репрограм дуга, потврда о измирењу дуга, повраћај новца, бонификација-умањење рачуна (ако је поремећај у раду система отклоњен  или констатована неадекватна температура а умањење задужења није исказано у одређеном рачуну).',
        subtypeData: [
          {
            value: 'Zahtev za zadavanje, obnovu, izmenu tehničkih uslova - CEOP',
            label: 'Захтев за задавање, обнову, измену техничких услова - ЦЕОП'
          },
          {
            value: 'Zahtev za pregled projekata',
            label: 'Захтев за преглед пројеката'
          },
          {
            value: 'Zahtev za predugovor',
            label: 'Захтев за предуговор'
          },
          {
            value: 'Zahtev za rešenje o priključenju',
            label: 'Захтев за решење о прикључењу'
          },
          {
            value: 'Zahtev za ugovor o nedostajućoj infrastrukturi',
            label: 'Захтев за уговор о недостајућој инфраструктури'
          },
          {
            value: 'Prijava za priključenje',
            label: 'Пријава за прикључење'
          },
          {
            value: 'Zahtev za isključenje',
            label: 'Захтев за искључење'
          },
          {
            value: 'Zahtev za ponovno priključenje',
            label: 'Захтев за поновно прикључење'
          },
          {
            value: 'Zahtev za spajanje prostora',
            label: 'Захтев за спајање простора',
          },
          {
            value: 'Zahtev za podelu prostora',
            label: 'Захтев за поделу простора'
          },
          {
            value: 'Zahtev za promenu parametara obračuna',
            label: 'Захтев за промену параметара обрачуна'
          },
          {
            value: 'Zahtev za razdvajanje predajne stanice',
            label: 'Захтев за раздвајање предајне станице'
          },
          {
            value: 'Prijava za kontrolu priključenosti',
            label: 'Пријава за контролу прикључености'
          },
          {
            value: 'Zahtev za reprogram duga',
            label: 'Захтев за репрограм дуга'
          },
          {
            value: 'Zahtev za bonifikaciju - umanjenje računa',
            label: 'Захтев за бонификацију - умањење рачуна'
          },
          {
            value: 'Zahtev za potvrdu o izmirenju duga',
            label: 'Захтев за потврду о измирењу дуга'
          },
          {
            value: 'Zahtev za pražnjenje instalacija',
            label: 'Захтев за пражњење инсталација'
          },
          {
            value: 'Zahtev za povraćaj novca',
            label: 'Захтев за повраћај новца'
          }
        ]
      },
      {
        name: 'Захтев за исправку података о партнеру',
        nameLatin: 'Zahtev za ispravku podataka o partneru',
        description: 'Уговор о испоруци топлотне енергије / гаса, уговор о одобрењу прикључењa',
        subtypeData: [
          {
            value: 'Ugovor o isporuci toplotne energije/gasa',
            label: 'Уговор о испоруци топлотне енергије/гаса'
          },
          {
            value: 'Ugovor o odobrenju priključenja',
            label: 'Уговор о одобрењу прикључења'
          }
        ]
      }
    ]
  },
  {
    referId: 5,
    data: [
      {
        name: 'Приговор на одговор на рекламацију',
        nameLatin: 'Prigovor na odgovor na reklamaciju',
        description: 'Oдговор на рекламацију',
        subtypeData: []
      },
      {
        name: 'Приговор на документ',
        nameLatin: 'Prigovor na dokument',
        description: 'Остала документа',
        subtypeData: []
      }
    ]
  }

]

export const spaceTypeData = [
  {
    value: 'Poslovni prostor',
    label: 'Пословни простор'
  },
  {
    value: 'Stambeni prostor',
    label: 'Стамбени простор'
  },
  {
    value: 'Garaža',
    label: 'Гаража'
  },
  {
    value: 'Zajedničke prostorije',
    label: 'Заједничке просторије'
  },
  {
    value: 'Ostalo',
    label: 'Остало'
  }
];

export const callCenterReferTypes = [
  {
    name: 'Цурење',
    nameLatin: 'Curenje',
    subtypeData: [
      {
        value: 'Curenje u stanu',
        label: 'Цурење у стану'
      },
      {
        value: 'Curenje u podrumu',
        label: 'Цурење у подруму'
      },
      {
        value: 'Curenje iz instalacija u podstanici',
        label: 'Цурење из инсталација у подстаници'
      },
      {
        value: 'Curenje vode iz zajedničkih instalacija',
        label: 'Цурење воде из заједничких инсталација'
      },
      {
        value: 'Curenje vode iz toplovoda',
        label: 'Цурење воде из топловода'
      }

    ]
  },
  {
    name: 'Негрејање',
    nameLatin: 'Negrejanje',
    subtypeData: [
      {
        value: 'Ceo objekat ili deo objekta nema grejanje',
        label: 'Цео објекат или део објекта нема грејање'
      },
      {
        value: 'Ceo prostor ili deo prostora nema grejanje',
        label: 'Цео простор или део простора нема грејање'
      }

    ]
  },
  {
    name: 'Температура испод прописане',
    nameLatin: 'Temperatura ispod propisane',
    subtypeData: [
      {
        value: 'Loše grejanje u prostoru ili delu prostora',
        label: 'Лоше грејање у простору или делу простора'
      }

    ]
  },
  {
    name: 'Потрошна топла вода',
    nameLatin: 'Potrošna topla voda',
    subtypeData: [
      {
        value: 'Nema dotoka tople vode',
        label: 'Нема дотока топле воде'
      },
      {
        value: 'Neodgovarajuća temperatura tople vode',
        label: 'Неодговарајућа температура топле воде'
      },
      {
        value: 'Prljava topla voda',
        label: 'Прљава топла вода'
      }
    ]
  },
  {
    name: 'Бука',
    nameLatin: 'Buka',
    subtypeData: [
      {
        value: 'Buka u podstanici',
        label: 'Бука у подстаници'
      }

    ]
  }

]